import { RocketLaunch } from "@mui/icons-material";
import {
  Backdrop,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Container,
} from "@mui/material";
import * as Sentry from "@sentry/browser";
import RocketAnimation from "assets/animations/rocket-animation.json";
import Lottie from "lottie-react";
import ms from "ms";
import { useRegisterSW } from "virtual:pwa-register/react";

function ReloadPrompt() {
  const {
    needRefresh: [needRefresh],
    updateServiceWorker,
  } = useRegisterSW({
    onRegistered(r) {
      r &&
        setInterval(() => {
          r.update();
        }, ms("60s"));
    },
    onRegisterError(error) {
      Sentry.captureException(error);
      console.error("SW registration error: %o", error);
    },
  });

  return (
    <>
      {needRefresh && (
        <div>
          <Backdrop open sx={{ zIndex: 3 }}>
            <Container maxWidth="sm">
              <Card>
                <CardHeader
                  title="Update Required"
                  subheader={"You must be on the latest version to continue"}
                />
                <CardContent>
                  <Lottie style={{ height: "200px" }} animationData={RocketAnimation} />
                </CardContent>
                <CardActions sx={{ justifyContent: "flex-end", display: "flex" }}>
                  <Button
                    onClick={() => {
                      updateServiceWorker(true);
                    }}
                    variant="contained"
                    startIcon={<RocketLaunch />}
                  >
                    Update and reload
                  </Button>
                </CardActions>
              </Card>
            </Container>
          </Backdrop>
        </div>
      )}
    </>
  );
}

export default ReloadPrompt;
