import { FieldValues, Path, Primitive } from "react-hook-form";
import { Keys } from "react-hook-form/dist/types/path/common";

/**
 * Omit any number of keys from an object.
 *
 * Does not modify the original object.
 */
export function omit<TValue extends { [key: string]: unknown}, TKey extends keyof TValue >(
  object: TValue,
  ...keys: TKey[]
): Omit<TValue, TKey> {
  const output: Record<string, unknown> = {};
  const exclusions = new Set(keys);

  for (const [key, value] of Object.entries(object)) {
    if (!exclusions.has(key as TKey)) {
      output[key] = value;
    }
  }

  return output as Omit<TValue, TKey>;
}

/**
 * A type that can be used like the `Path` type from `react-hook-form`
 * but filters the possible paths down to only those that match a certain
 * type.
 */
export type FilteredPath<FormValues extends FieldValues, ValueType = Primitive> = Exclude<
  // This type includes all the keys, including nested values, in an object
  Path<FormValues>,
  // We want to exclude any of the paths that don't match the provided type
  Exclude<Keys<FormValues>, Keys<FormValues, ValueType>>
>;

/**
 * An object key that be used as a [[ValueAccessor]] in the [[accessValue]] function.
 */
export type ValueAccessorKey<
  TObject extends Record<any, any>,
  TReturn = any
> = FilteredPath<TObject, TReturn>;

/**
 * An accessor function that can be used as a [[ValueAccessor]] in the [[accessValue]] function.
 */
export type ValueAccessorFunction<TObject extends Record<any, any>, TReturn> = (
  item: TObject
) => TReturn;

/**
 * A labeled object with an accessor function attached that can be used as a [[ValueAccessor]]
 * in the [[accessValue]] function.
 */
export type ValueAccessorLabeledObject<
  TObject extends Record<any, any>,
  TReturn = any
> = {
  label: string;
  accessor: ValueAccessorFunction<TObject, TReturn>;
};

/**
 * Either a key of an object or a function that accepts the object and returns a value.
 *
 * Used in the [[accessValue]] function.
 */
export type ValueAccessor<TObject extends Record<any, any>, TReturn = any> =
  | ValueAccessorKey<TObject, TReturn>
  | ValueAccessorFunction<TObject, TReturn>
  | ValueAccessorLabeledObject<TObject, TReturn>;

/**
 * Access a property of an object using a key.
 *
 * This function is mainly used for the the type inference that can be achieved when combining accessor
 * functions and keys.
 */
export function accessValue<TObject extends Record<any, any>, TKey extends keyof TObject>(
  object: TObject,
  key: TKey
): TObject[TKey];
/**
 * Retrieves a value from an object using a function.
 *
 * This function is mainly used for the the type inference that can be achieved when combining accessor
 * functions and keys.
 */
export function accessValue<TObject extends Record<any, any>, TReturn = any>(
  object: TObject,
  accessor: ValueAccessorFunction<TObject, TReturn>
): TReturn;
/**
 * Retrieves a value from an object using a function contained within a labelled object.
 *
 * This function is mainly used for the the type inference that can be achieved when combining accessor
 * functions and keys.
 */
export function accessValue<TObject extends Record<any, any>, TReturn = any>(
  object: TObject,
  accessor: ValueAccessorLabeledObject<TObject, TReturn>
): TReturn;
/**
 * Retrieves a value from an object using a function contained within a labelled object.
 *
 * This function is mainly used for the the type inference that can be achieved when combining accessor
 * functions and keys.
 */
export function accessValue<TObject extends Record<any, any>, TReturn>(
  object: TObject,
  accessor: ValueAccessor<TObject, TReturn>
): TReturn;
export function accessValue<TObject extends Record<any, any>>(
  object: TObject,
  accessor: ValueAccessor<TObject>
): any {
  if (typeof accessor === "object") return accessor.accessor(object);
  if (typeof accessor === "function") return accessor(object);
  return get(object, accessor);
}

/**
 *
 * Gets the value at path of object. If the resolved value is undefined, the defaultValue is returned in its place.
 *
 * @param object The object to query
 * @param path The path of the property to get
 * @param defaultValue The value returned for undefined resolved values
 * @return The value if it exists, if not then either the default value is returned or undefined
 *
 */
export function get<T = any>(
  object: object,
  path: string[] | string,
  defaultValue?: T
): T | undefined {
  const arrayAccessor = /\[(\d+)\]/g;

  // If the path was a string, split it by periods
  if (typeof path === "string") path = path.replace(arrayAccessor, ".$1").split(".");

  const nextKey = path.shift() as keyof object;

  // Up to the last section of the path, get the value now
  if (path.length === 0) {
    const value = object[nextKey as keyof object];
    if (value !== undefined) return value;
    return defaultValue;
  }

  // If the next key isn't an object then we can't read it
  if (!object[nextKey] || typeof object[nextKey] !== "object") {
    return defaultValue;
  }

  // Call get recursively with the next section of the path
  return get(object[nextKey], path, defaultValue);
}

export default accessValue;
