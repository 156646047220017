import { TableCell, TableHead, TableRow, TableSortLabel } from "@mui/material";
import { styled } from "@mui/material/styles";

export const NoWrapTableCell = styled(TableCell)`
  white-space: nowrap;
`;

const styledTableRowOptions = {
  shouldForwardProp: (prop: string) => prop !== "highlight",
};

const StyledTableRow = styled(TableRow, styledTableRowOptions)<{ highlight?: boolean }>`
  background-color: ${({ highlight, theme: { palette } }) =>
    highlight
      ? palette.mode === "dark"
        ? palette.grey[800]
        : palette.grey[100]
      : undefined};
`;

export interface TableHeadColumn {
  label: string;
  field?: string;
  sortable?: boolean;
  width?: string;
  align?: "left" | "center" | "right";
}

interface TableHeadingsProps {
  headings: TableHeadColumn[];
  orderBy?: string;
  orderDirection?: "asc" | "desc";
  handleSortPressed?: (field: string) => void;
  highlight?: boolean;
}

function TableHeadings(props: TableHeadingsProps) {
  const { headings, orderBy, orderDirection, handleSortPressed, highlight } = props;
  return (
    <TableHead>
      <StyledTableRow highlight={highlight}>
        {headings.map((heading, index) => {
          return (
            <TableCell
              key={heading.label ?? heading.field ?? index}
              width={heading.width}
              align={heading.align}
              sortDirection={orderBy === heading.field ? orderDirection : false}
            >
              {heading.sortable ? (
                <TableSortLabel
                  active={orderBy === heading.field}
                  hideSortIcon
                  direction={orderDirection}
                  onClick={() =>
                    heading.sortable &&
                    handleSortPressed &&
                    handleSortPressed(heading?.field ?? "")
                  }
                >
                  {heading.label}
                </TableSortLabel>
              ) : (
                heading.label
              )}
            </TableCell>
          );
        })}
      </StyledTableRow>
    </TableHead>
  );
}

export default TableHeadings;
