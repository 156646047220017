import { Card, Stack, Typography, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import LoadingAnimation from "assets/animations/loading-animation.json";
import dgi_logo from "assets/images/dgi_logo.png";
import dgi_logo_darktheme from "assets/images/dgi_logo_darktheme.png";
import Lottie from "lottie-react";

interface SplashScreenProps {
  loadingText: string;
  position?: "center" | "relative";
}

// position = relative: loader position set by parent
// position = center: Loader forced in center of screen
function SplashScreen(props: SplashScreenProps) {
  const { loadingText = "Loading Periculum...", position = "relative" } = props;
  const theme = useTheme();

  return (
    <Box
      sx={{
        position: "absolute",
        top: "0",
        width: "100vw",
        height: "100vh",
        left: position === "center" ? 0 : "",
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        height="100vh"
        width="100%"
      >
        <Card
          variant="elevation"
          elevation={9}
          sx={(theme) => ({
            py: "32px",
            [theme.breakpoints.down("sm")]: {
              width: "100%",
            },
            [theme.breakpoints.up("sm")]: {
              width: "300px",
              minHeight: "285px",
            },
          })}
        >
          <Stack spacing={3} sx={{ alignItems: "center" }}>
            <img
              src={theme.palette.mode === "light" ? dgi_logo : dgi_logo_darktheme}
              alt="Loading"
              height="50"
            />
            <div>
              <Typography variant="h6" align="center">
                {loadingText}
              </Typography>
              <Lottie animationData={LoadingAnimation} style={{ height: 75 }} />
            </div>
          </Stack>
        </Card>
      </Box>
    </Box>
  );
}

export default SplashScreen;
