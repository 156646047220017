import { BookingTypeMap } from "@dgi/utils";
import {
  AccountTree as InterOfficeIcon,
  EuroSymbol as EuropeIcon,
  House as DomesticIcon,
  LocationCity as CityMetroIcon,
  NoteAdd as ShipDecAndPackIcon,
  PlayForWork as ImportIcon,
  Public as InternationalIcon,
  Redeem as PackagingIcon,
} from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import { styled } from "@mui/system";
import { BookingType } from "services/graphql/generated";

const IconWrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-end",
  justifyContent: "center",
});

interface BookingIconProps {
  bookingType: BookingType;
  iconSize?: "small" | "medium" | "large";
  checked?: boolean;
}

function BookingIcon(props: BookingIconProps) {
  const { bookingType, iconSize = "small", checked = false } = props;

  const tooltipText = BookingTypeMap[bookingType];

  switch (bookingType) {
    case BookingType.CityMetro:
      return (
        <Tooltip title={tooltipText}>
          <IconWrapper>
            <CityMetroIcon
              sx={{ color: checked ? "secondary.main" : "primary.main" }}
              fontSize={iconSize}
            />
          </IconWrapper>
        </Tooltip>
      );
    case BookingType.Domestic:
      return (
        <Tooltip title={tooltipText}>
          <IconWrapper>
            <DomesticIcon fontSize={iconSize} />
          </IconWrapper>
        </Tooltip>
      );
    case BookingType.ShipDecAndPack:
      return (
        <Tooltip title={tooltipText}>
          <IconWrapper>
            <ShipDecAndPackIcon fontSize={iconSize} />
          </IconWrapper>
        </Tooltip>
      );
    case BookingType.Packaging:
      return (
        <Tooltip title={tooltipText}>
          <IconWrapper>
            <PackagingIcon fontSize={iconSize} />
          </IconWrapper>
        </Tooltip>
      );
    case BookingType.International:
      return (
        <Tooltip title={tooltipText}>
          <IconWrapper>
            <InternationalIcon fontSize={iconSize} />
          </IconWrapper>
        </Tooltip>
      );
    case BookingType.Import:
      return (
        <Tooltip title={tooltipText}>
          <IconWrapper>
            <ImportIcon fontSize={iconSize} />
          </IconWrapper>
        </Tooltip>
      );
    case BookingType.Europe:
      return (
        <Tooltip title={tooltipText}>
          <IconWrapper>
            <EuropeIcon fontSize={iconSize} />
          </IconWrapper>
        </Tooltip>
      );
    case BookingType.InterOffice:
      return (
        <Tooltip title={tooltipText}>
          <IconWrapper>
            <InterOfficeIcon fontSize={iconSize} />
          </IconWrapper>
        </Tooltip>
      );
    default:
      return null;
  }
}

export default BookingIcon;
