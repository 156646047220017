import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "Periculum.css";
import "services/sentry";

import { config } from "config";

import { AppState, Auth0Provider } from "@auth0/auth0-react";
import { CssBaseline } from "@mui/material";
import App from "App";
import AuthorizedApolloProvider from "contexts/AuthorizedApolloProvider";
import GlobalErrorBoundary from "GlobalErrorBoundary";
import { createRoot } from "react-dom/client";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Login from "screens/Login/Login";
import history from "utils/history";

const onRedirectCallback = (appState?: AppState) => {
  history.replace(appState?.returnTo || window.location.pathname);
};

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
  <Auth0Provider
    domain={config.SERVICES.AUTH0.DOMAIN}
    clientId={config.SERVICES.AUTH0.PERICULUM.CLIENT_ID}
    authorizationParams={{
      redirect_uri: window.location.origin,
      audience: config.SERVICES.AUTH0.PERICULUM.AUDIENCE,
      scope: "openid profile email",
    }}
    onRedirectCallback={onRedirectCallback}
    useRefreshTokens
    cacheLocation="localstorage"
  >
    <BrowserRouter>
      <CssBaseline />
      <GlobalErrorBoundary>
        <AuthorizedApolloProvider>
          <Switch>
            <Route path="/login">
              <Login />
            </Route>

            <Route path="*">
              <App />
            </Route>
          </Switch>
        </AuthorizedApolloProvider>
      </GlobalErrorBoundary>
    </BrowserRouter>
  </Auth0Provider>
);
