import { Grid, Typography } from "@mui/material";
import { styled } from "@mui/system";
import { Breadcrumbs } from "components";
import { useDocument } from "contexts/DocumentContext";
import React from "react";

const RootContainer = styled(Grid)(({ theme }) => ({
  maxWidth: "100%",
  margin: "85px auto 50px auto",
  justifyContent: "center",
  alignItems: "center",
  padding: 2,
  [theme.breakpoints.up("sm")]: {
    maxWidth: "1450px",
  },
  "@media print": {
    margin: 0,
  },
}));

interface ScreenProps {
  title?: string;
  children?: React.ReactNode;
  subtitle?: string | React.ReactNode;
}

function Screen(props: ScreenProps) {
  const { title = "", children, subtitle, ...otherProps } = props;
  const { breadcrumbs } = useDocument();

  const subtitleElem =
    typeof subtitle === "string" ? (
      <Typography variant="h6">{subtitle}</Typography>
    ) : (
      subtitle
    );

  return (
    <RootContainer container spacing={2} {...otherProps}>
      <Grid item xs={12}>
        <Breadcrumbs state={breadcrumbs} />
      </Grid>
      <Grid item xs={12}>
        <Typography
          variant="h2"
          sx={{
            color: (theme) => theme.palette.text.primary,
          }}
        >
          {title}
        </Typography>
      </Grid>
      {subtitle && (
        <Grid item xs={12}>
          {subtitleElem}
        </Grid>
      )}
      <Grid item xs={12} style={{ marginTop: "12px" }}>
        {children}
      </Grid>
    </RootContainer>
  );
}

export default Screen;
