import { Cancel, CheckCircle } from "@mui/icons-material";
import { useTheme } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { SpeedDialer } from "components";
import PrivateRoutes from "config/PrivateRoutes";
import { Toaster } from "react-hot-toast";
import Background from "screens/GlobalNav/Background";
import Navigation from "screens/GlobalNav/Navigation";
import "react-quill/dist/quill.snow.css";
import ReloadPrompt from "config/ReloadPrompt";

function Root() {
  const muiThemeObject = useTheme();

  return (
    <Background>
      <Toaster
        position="top-center"
        toastOptions={{
          style: {
            minWidth: "250px",
            background: muiThemeObject.palette.background.paper,
            color: muiThemeObject.palette.text.primary,
          },
          success: {
            icon: <CheckCircle sx={{ color: "success.main" }} />,
          },
          error: {
            icon: <Cancel sx={{ color: "error.main" }} />,
          },
        }}
      />
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Navigation>
          <PrivateRoutes />
        </Navigation>
      </LocalizationProvider>
      <SpeedDialer />
      <ReloadPrompt />
    </Background>
  );
}

export default Root;
