import { bool, cleanEnv, str } from "envalid";

// Manually setting NODE_ENV so envalid knows which set of defaults to use
const currentEnv = { ...import.meta.env, NODE_ENV: import.meta.env.MODE };

export const env = cleanEnv(currentEnv, {
  VITE_SW_ENABLED: str({
    desc: "Whether to enable the service worker",
    default: "true",
    devDefault: "false",
  }),
  VITE_BUNDLE_ANALYZER: bool({
    desc: "Whether to enable the bundle analyzer",
    devDefault: true,
    default: false,
    choices: [true, false],
  }),
  VITE_API_URL: str({
    desc: "The DGI backend api web server endpoint",
    default: "https://dgi-express.dgiglobal.com",
    devDefault: "http://localhost:8081",
  }),
  VITE_SUBSCRIPTION_URL: str({
    desc: "The DGI backend api web socket endpoint",
    default: "wss://dgi-express.dgiglobal.com/subscriptions",
    devDefault: "ws://localhost:8081/subscriptions",
  }),

  VITE_AUTH0_DOMAIN: str({
    desc: "The domain of the Auth0 tenant",
    default: "dgi-prod.au.auth0.com",
    devDefault: "dgi-dev.au.auth0.com",
  }),

  VITE_AUTH0_AUDIENCE: str({
    desc: "The DGI Express API audience",
    default: "https://dgi-express.dgiglobal.com",
    devDefault: "https://dev.dgi-express.dgiglobal.com",
  }),

  VITE_AUTH0_CLIENT_ID: str({
    desc: "Application client ID for Periculum within Auth0",
    default: "pJG9ILEmMrvwjqUS935udmLY2Zvz0VDJ",
    devDefault: "kpDlTx5xvypoOSzqILIQpVZOVIu24Yp5",
  }),

  VITE_SENTRY_DSN: str({
    desc: "The DSN for the Sentry error reporting service",
    default: "https://53714d8978ca44ca9010c10406d106fa@sentry.io/1798079",
  }),
});
