import { ButtonBase, Card, CardContent, Typography } from "@mui/material";
import { styled } from "@mui/system";
import { useHistory } from "react-router";

interface FeatureCardProps {
  route: string;
  title: string;
  icon?: JSX.Element;
  disabled?: boolean;
}

const StyledButtonBase = styled(ButtonBase)({
  width: "100%",
  padding: "10px",
  borderRadius: "5px",
  "&:hover svg": {
    transform: "scale(1.1)",
  },
});

function RouteCard(props: FeatureCardProps) {
  const { disabled = false, route, title, icon } = props;
  const history = useHistory();
  return (
    <Card sx={{ p: 0 }}>
      <CardContent
        sx={{
          borderTop: "3px solid",
          borderColor: "secondary.main",
          "&:last-child": { p: 2 },
        }}
      >
        <StyledButtonBase
          disabled={disabled}
          sx={{ backgroundColor: "background.paper" }}
          onClick={() => history.push(route)}
        >
          <Typography variant="h6">{title}</Typography>
          {icon}
        </StyledButtonBase>
      </CardContent>
    </Card>
  );
}

export default RouteCard;
