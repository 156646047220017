/** A booking status */
export enum BookingJobStatus {
  Cancelled = "Cancelled", //= -1,
  Confirmed = "Confirmed", // = 0,
  PickedUp = "PickedUp", // = 1,
  Arrived = "Arrived", // = 2,
  InTransit = "InTransit", // = 3,
  Delivered = "Delivered", // = 4,
}

/** Map a booking status to a label */
export const JobStatusMap: Record<BookingJobStatus, string> = {
  [BookingJobStatus.Cancelled]: "Cancelled",
  [BookingJobStatus.Confirmed]: "Confirmed",
  [BookingJobStatus.PickedUp]: "Picked up",
  [BookingJobStatus.Arrived]: "Arrived",
  [BookingJobStatus.InTransit]: "In-Transit",
  [BookingJobStatus.Delivered]: "Delivered",
};

export const JobStatusColour: Record<BookingJobStatus, string> = {
  [BookingJobStatus.Cancelled]: "rgba(235,235,235,1)",
  [BookingJobStatus.Confirmed]: "rgba(50, 127, 212, 0.15)",
  [BookingJobStatus.PickedUp]: "rgba(201, 60, 63, 0.15)",
  [BookingJobStatus.Arrived]: "rgba(253, 209, 86, 0.15)",
  [BookingJobStatus.InTransit]: "rgba(57, 169, 108, 0.15)",
  [BookingJobStatus.Delivered]: "rgba(111, 111, 111, 0.15)",
};

export const JobStatusColours: Record<
  BookingJobStatus,
  { background: string; text: string }
> = {
  [BookingJobStatus.Cancelled]: {
    background: "rgba(0,0,0,0.5)",
    text: "rgb(255,255,255)",
  },
  [BookingJobStatus.Confirmed]: {
    background: "rgba(50, 127, 212, 0.15)",
    text: "rgb(0, 101, 213)",
  },
  [BookingJobStatus.PickedUp]: {
    background: "rgba(201, 60, 63, 0.15)",
    text: "rgb(205, 20, 24)",
  },
  [BookingJobStatus.Arrived]: {
    background: "rgba(253, 209, 86, 0.15)",
    text: "rgb(135, 99, 0)",
  },
  [BookingJobStatus.InTransit]: {
    background: "rgba(57, 169, 108, 0.15)",
    text: "rgb(0, 120, 55)",
  },
  [BookingJobStatus.Delivered]: {
    background: "rgba(111, 111, 111, 0.15)",
    text: "rgb(0, 0, 0)",
  },
};

/** A booking step */
export enum BookingJobStep {
  Details = "Details", // = 0,
  Shipments = "Shipments", // = 1,
  Pickup = "Pickup", // = 2,
  Arrival = "Arrival", // = 3,
  Transport = "Transport", // = 4,
  Delivery = "Delivery", // = 5,
}

/** The type of the booking */
export enum BookingType {
  Domestic = "Domestic",
  International = "International",
  ShipDecAndPack = "ShipDecAndPack",
  CityMetro = "CityMetro",
  InterOffice = "InterOffice",
  Europe = "Europe",
  Import = "Import",
  Packaging = "Packaging",
}

/** Map a BookingType to a label */
export const BookingTypeMap: Record<BookingType, string> = {
  [BookingType.Domestic]: "Domestic",
  [BookingType.International]: "International",
  [BookingType.CityMetro]: "City Metro",
  [BookingType.InterOffice]: "Inter-Office",
  [BookingType.ShipDecAndPack]: "Dec & Pack",
  [BookingType.Import]: "Import",
  [BookingType.Europe]: "Europe",
  [BookingType.Packaging]: "Packaging",
};

export type AccountChargeShipmentType =
  | "charge_international"
  | "charge_domestic"
  | "charge_shipdecpack"
  | "charge_import"
  | "charge_euro"
  | "charge_interoffice"
  | "charge_citymetro"
  | "charge_packaging";

/** Map a booking type to the column in the AccountCharges table */
export const bookingTypeChargeColumn: Record<BookingType, AccountChargeShipmentType> = {
  International: "charge_international",
  Domestic: "charge_domestic",
  ShipDecAndPack: "charge_shipdecpack",
  Import: "charge_import",
  Europe: "charge_euro",
  InterOffice: "charge_interoffice",
  CityMetro: "charge_citymetro",
  Packaging: "charge_packaging",
};

/** An account charge type. Flat amount of percentage based on base freight charge */
export enum AccountChargeType {
  Fixed = "Fixed",
  Percentage = "Percentage",
}

/** Account business code options */
export enum AccountBusinessCode {
  GST = "GST",
  VAT = "VAT",
  TAX = "TAX",
  ABN = "ABN",
  Siret = "Siret",
}

/** Aircraft type options for shipments */
export enum ShipmentAircraftType {
  NotRequired = "NotRequired",
  Cargo = "Cargo",
  LimitedQuantity = "LimitedQuantity",
  Passenger = "Passenger",
}

/** Map a shipment aircraft type to a label */
export const ShipmentAircraftTypeMap: Record<ShipmentAircraftType, string> = {
  [ShipmentAircraftType.NotRequired]: "Not Required",
  [ShipmentAircraftType.Cargo]: "Cargo",
  [ShipmentAircraftType.LimitedQuantity]: "Limited Quantity",
  [ShipmentAircraftType.Passenger]: "Passenger",
};

/** Shipment hazard options */
export enum ShipmentHazardous {
  yes = "yes",
  no = "no",
  pack = "pack",
  other = "other",
}

/** Map a shipment hazardous to a label */
export const ShipmentHazardousMap: Record<ShipmentHazardous, string> = {
  [ShipmentHazardous.yes]: "Hazardous",
  [ShipmentHazardous.no]: "Non-Hazardous",
  [ShipmentHazardous.pack]: "Packaging only",
  [ShipmentHazardous.other]: "Other",
};

/** Volumetric weight constant options for calculating the volumetric mass of a shipment */
export const volumetricWeightConstantOptions = [4000, 6000] as const;

/** Map a volumetric weight constant to a label */
export const volumetricWeightConstantLabelMap: Record<number, string> = {
  [4000]: "Domestic (4000)",
  [6000]: "International (6000)",
};

/** The pickup aircraft type */
export enum PickupAircraftType {
  PassengerCargo = "PassengerCargo",
  CargoOnly = "CargoOnly",
}

/** Map a Pickup Aircraft type to a label */
export const PickupAircraftTypeMap: Record<PickupAircraftType, string> = {
  [PickupAircraftType.PassengerCargo]: "Passenger Cargo",
  [PickupAircraftType.CargoOnly]: "Cargo Only",
};

/** Pickup collection method */
export enum PickupCollectionMethod {
  Overnight = "Overnight",
  Road = "Road",
  SameDay = "SameDay",
  DGI = "DGI",
}

/** Map pickup collection to a label */
export const PickupCollectionMethodMap: Record<PickupCollectionMethod, string> = {
  [PickupCollectionMethod.Overnight]: "Overnight",
  [PickupCollectionMethod.Road]: "Road",
  [PickupCollectionMethod.SameDay]: "Same Day",
  [PickupCollectionMethod.DGI]: "DGI",
};

/** Booking note subject or type/source */
export enum BookingNoteSubject {
  booked = "booked",
  arrival = "arrival",
  flight = "flight",
  flightPrivate = "flightprivate",
  action = "action",
  user = "user",
  admin = "admin",
}

/** Maps booking note actions to booking notes */
export const BookingNoteTemplates = {
  /** When a booking is created */
  BookingCreated: "Customer booking created",

  /** When a booking is cancelled */
  BookingCancelled: "Booking has been cancelled",

  /** When a booking is uncancelled */
  BookingUncancelled: "Booking has been uncancelled",

  /** When a booking pickup record is complete */
  BookingPickedUp: (date: string) => `Shipment pick up arranged for ${date}`,

  /** When a booking arrival record is complete */
  BookingArrived: "Shipment arrived at DGI",

  /** When an agent prealert email is sent from the transport screen */
  PrealertSent: "Shipment in transit",

  /** When a booking transport record is saved */
  BookingTransportSavedPublic: (
    destinationPortcode: string,
    destinationCity: string | undefined,
    arrivalDate: string
  ) =>
    `Movement schedule booked. ETA at ${destinationPortcode}${
      destinationCity ? ` (${destinationCity})` : ""
    }: ${arrivalDate}`,

  /** When a booking transport record is saved */
  BookingTransportSavedPrivate: (
    mawb: string | undefined | null,
    agent: string | undefined | null,
    connote: string,
    arrivalDate: string
  ) => {
    const mawbText = mawb ? `MAWB: ${mawb}, ` : "";
    const agentText = agent ? `Agent: ${agent}, ` : "";
    const connoteText = connote ? `Connote: ${connote}, ` : "";
    const arrivalText = `Arrival date: ${arrivalDate}`;
    return `${mawbText}${agentText}${connoteText}${arrivalText}`;
  },

  /** When a booking transport record is marked as complete */
  BookingTransportComplete: (bookingType: BookingType) => {
    switch (bookingType) {
      case BookingType.International:
      case BookingType.Import:
      case BookingType.InterOffice:
        return "Awaiting clearance and delivery";
      case BookingType.Domestic:
      case BookingType.Europe:
      case BookingType.CityMetro:
        return "Awaiting delivery";
      case BookingType.Packaging:
      case BookingType.ShipDecAndPack:
      default:
        return undefined;
    }
  },

  /** When a booking delivery is complete */
  BookingDeliveryComplete: (date: string, name: string) =>
    `Shipment delivered ${date}, and signed by ${name}`,
} as const;

/** Is a DGD raised on the arrival phase of a booking */
export enum BookingArrivalDgdRaised {
  No = "No",
  Yes = "Yes",
  NotApplicable = "NotApplicable",
}

/** Maps is DGD raised db value to a label */
export const BookingArrivalDgdRaisedMap: Record<BookingArrivalDgdRaised, string> = {
  [BookingArrivalDgdRaised.No]: "No",
  [BookingArrivalDgdRaised.Yes]: "Yes",
  [BookingArrivalDgdRaised.NotApplicable]: "Not Applicable",
};

/** Feature request status codes */
export enum FeatureRequestStatus {
  Submitted = "Submitted", // 1,
  UnderReview = "UnderReview", // 2,
  InProgress = "InProgress", // 3,
  OnHold = "OnHold", // 4,
  NotGoingAhead = "NotGoingAhead", // 5,
  Completed = "Completed", // 6,
}

/** Map a feature request status to a display label */
export const mapFeatureRequestStatusToLabel: Record<FeatureRequestStatus, string> = {
  Submitted: "Submitted",
  InProgress: "In Progress",
  NotGoingAhead: "Not Going Ahead",
  OnHold: "On Hold",
  UnderReview: "Under Review",
  Completed: "Completed",
};

/** Map a feature request status to a text color */
export const mapFeatureRequestStatusToColor: Record<FeatureRequestStatus, string> = {
  Submitted: "text.primary",
  InProgress: "error.main",
  NotGoingAhead: "error.main",
  OnHold: "error.main",
  UnderReview: "text.primary",
  Completed: "success.main",
};

/** Forms that can be added to the transport screen prealert email to delivery agents */
// export enum AgentPrealertFormOption {
//   deliverynote = "deliverynote",
//   prealert = "prealert",
//   shippersdec = "shippersdec",
//   proforma1 = "proforma1",
//   proforma2 = "proforma2",
// }

// /** Map a document option to a label */
// export const AgentPrealertFormOptionMap: Record<AgentPrealertFormOption, string> = {
//   [AgentPrealertFormOption.deliverynote]: "Delivery Note",
//   [AgentPrealertFormOption.prealert]: "Prealert",
//   [AgentPrealertFormOption.shippersdec]: "Shippers Declaration",
//   [AgentPrealertFormOption.proforma1]: "Proforma Invoice 1",
//   [AgentPrealertFormOption.proforma2]: "Proforma Invoice 2",
// };

/** Map  */

/** Map a booking step to a label */
// export const JobStepMap: Record<BookingJobStep, string> = {
//   [BookingJobStep.DETAILS]: "Details",
//   [BookingJobStep.SHIPMENT_ITEMS]: "Shipments",
//   [BookingJobStep.PICKUP]: "Pickup",
//   [BookingJobStep.ARRIVAL]: "Arrival",
//   [BookingJobStep.TRANSPORT]: "Transport",
//   [BookingJobStep.DELIVERY]: "Delivery",
// };

/** Printable Periculum forms */
// export enum FORM_ID {
//   AIRLINE_BOOKING = 1,
//   AIRWAYBILL_CONSOL = 2,
//   AIRWAYBILL_HOUSE = 3,
//   AIRWAYBULL_MASTER = 4,
//   COLLECTION_NOTE = 5,
//   DELIVERY_NOTE = 6,
//   JOB_CARD = 7,
//   MAWB_LABEL = 8,
//   MULTI_MODAL_DEC = 9,
//   PROFORMA_INVOICE_1 = 10,
//   PROFORMA_INVOICE_2 = 11,
//   ROAD_DEC = 12,
//   ROAD_DEC_UK = 13,
//   SHIPMENT_MANIFEST = 14,
//   SHIPMENT_PRE_ALERT = 15,
//   SHIPPERS_DEC = 16,
//   SHIPPING_LABELS = 17,
//   QUOTE = 18,
//   INVOICE = 19,
// }

/** Makes a readonly tuple mutable again  */
export const mutable = <T>(t: T): { -readonly [K in keyof T]: T[K] } => t;

/** Available forms in Periculum */
export const DOCUMENT_DEFINITIONS_DEPRECATED = [
  {
    id: 1,
    title: "Airline Booking",
    active: true,
    templateFileName: "AirlineBookingTemplate",
    fileName: "AirlineBooking",
    // generator: new AirlineBooking(),
  },
  {
    id: 5,
    title: "Collection Note",
    active: true,
    templateFileName: "CollectionNoteTemplate",
    fileName: "CollectionNote",
    // generator: new CollectionNote(),
  },
  {
    id: 6,
    title: "Delivery Note",
    active: true,
    templateFileName: "DeliveryNoteTemplate",
    fileName: "DeliveryNote",
    // generator: new DeliveryNote(),
  },
  {
    id: 3,
    title: "House Airway Bill",
    active: true,
    templateFileName: "HouseAirwayBillTemplate",
    fileName: "HAWB",
    // generator: new HouseAirwayBill(),
  },
  {
    id: 14,
    title: "Shipment Manifest",
    active: true,
    templateFileName: "ManifestTemplate",
    fileName: "Manifest",
    // generator: new Manifest(),
  },
  {
    id: 10,
    title: "Proforma Invoice 1",
    active: true,
    templateFileName: "ProformaInvoice1Template",
    fileName: "ProformaInvoice1",
    // generator: new ProformaInvoice1(),
  },
  {
    id: 16,
    title: "Shippers Declaration",
    active: true,
    templateFileName: "ShippersDec",
    fileName: "ShippersDec",
    // generator: new ShippersDec(),
  },
  {
    id: 15,
    title: "Shipment Pre-Alert",
    active: true,
    templateFileName: "ShipmentPrealertTemplate",
    fileName: "ShipmentPrealert",
    // generator: new ShipmentPrealert(),
  },
  {
    id: 19,
    title: "Invoice",
    active: true,
    templateFileName: "InvoiceTemplate",
    fileName: "Invoice",
    // generator: new Invoice(),
  },
  {
    id: 18,
    title: "Quote",
    active: true,
    templateFileName: "QuoteTemplate",
    fileName: "Quote",
    // generator: new Quote(),
  },
  {
    id: 12,
    title: "Road Declaration",
    active: true,
    templateFileName: "RoadDecTemplate",
    fileName: "RoadDec",
    // generator: new RoadDec(),
  },
  {
    id: 13,
    title: "Road Declaration UK",
    active: true,
    templateFileName: "RoadDecUKTemplate",
    fileName: "RoadDecUK",
    // generator: new RoadDecUK(),
  },
  {
    id: 17,
    title: "Shipping Labels",
    active: true,
    templateFileName: "ShippingLabels",
    fileName: "ShippingLabels",
    // generator: new ShippingLabel(),
  },
] as const;

/** Job tariff categories */
export enum JobTariffCategory {
  MANUAL_QUOTE = "MANUAL_QUOTE", // = "MQ",
  IMPORT_OVERNIGHT = "IMPORT_OVERNIGHT", // = "XA",
  DOMESTIC_SAMEDATE = "DOMESTIC_SAMEDATE", // = "DS",
  DOMESTIC_ROAD_BULK = "DOMESTIC_ROAD_BULK", // = "DRB",
  INTERNATIONAL_ROAD_BULK = "INTERNATIONAL_ROAD_BULK", // = "IRB",
  INTERNATIONAL_ROAD = "INTERNATIONAL_ROAD", // = "IR",
  INTERNATIONAL_AIR = "INTERNATIONAL_AIR", // = "IA",
  DOMESTIC_ROAD = "DOMESTIC_ROAD", // = "DR",
  INTERNATIONAL_AIR_BULK = "INTERNATIONAL_AIR_BULK", // = "IAB",
  DOMESTIC_OVERNIGHT = "DOMESTIC_OVERNIGHT", // = "DA",
  DOMESTIC_OVERNIGHT_BULK = "DOMESTIC_OVERNIGHT_BULK", // = "DAB",
  IMPORT_SAMEDAY = "IMPORT_SAMEDAY", // = "XS",
}

/** Map Job Tariff category codes to labels */
export const jobtariffCategoryMap: Record<JobTariffCategory, string> = {
  [JobTariffCategory.MANUAL_QUOTE]: "Manual Quote",
  [JobTariffCategory.IMPORT_OVERNIGHT]: "Import / Overnight",
  [JobTariffCategory.DOMESTIC_SAMEDATE]: "Domestic/Sameday",
  [JobTariffCategory.DOMESTIC_ROAD_BULK]: "Domestic / Road / Bulk",
  [JobTariffCategory.INTERNATIONAL_ROAD_BULK]: "International / Road / Bulk",
  [JobTariffCategory.INTERNATIONAL_ROAD]: "International / Road",
  [JobTariffCategory.INTERNATIONAL_AIR]: "International / Air",
  [JobTariffCategory.DOMESTIC_ROAD]: "Domestic / Road",
  [JobTariffCategory.INTERNATIONAL_AIR_BULK]: "International / Air / Bulk",
  [JobTariffCategory.DOMESTIC_OVERNIGHT]: "Domestic / Overnight",
  [JobTariffCategory.DOMESTIC_OVERNIGHT_BULK]: "Domestic / Overnight / Bulk",
  [JobTariffCategory.IMPORT_SAMEDAY]: "Import / Sameday",
} as const;

/** Names of the standard DGI charges */
export enum StandardChargeNames {
  FreightCharges = "Freight charges",
  FuelSurcharge = "Fuel Surcharge",
  DryIce = "Dry Ice",
  DGCheckAir = "DG Check - Air",
  DGCheckRoad = "DG Check - Road",
  Packaging = "Packaging",
  OutOfAreaSurcharge = "Out of Area Surcharge",
}

/** The booking delivery type specified for a booking during the Transport phase */
export enum BookingDeliveryType {
  DGI = "DGI",
  Overnight = "Overnight",
  Road = "Road",
  SameDay = "SameDay",
}

/** Map booking delivery enum to text label */
export const bookingDeliveryTypeMap: Record<BookingDeliveryType, string> = {
  [BookingDeliveryType.DGI]: "DGI",
  [BookingDeliveryType.Overnight]: "Overnight",
  [BookingDeliveryType.Road]: "Road",
  [BookingDeliveryType.SameDay]: "Same day",
};

/** Mapping delivery types to their corrosponding column in the AccountCharges table */
export type DeliveryTypeColumns =
  | "delivery_dgi"
  | "delivery_overnight"
  | "delivery_sameday"
  | "delivery_road";

// Converts a delivery type to the corrosponding column lookup in [CLIENTEXTRACOST]
/** Map a delivery type to the column in the AccountCharges table */
export const deliveryTypeChargeColumn: Record<BookingDeliveryType, DeliveryTypeColumns> =
  {
    DGI: "delivery_dgi",
    Overnight: "delivery_overnight",
    SameDay: "delivery_sameday",
    Road: "delivery_road",
  };

/** Industry types used by Periculum accounts */
export enum AccountIndustryType {
  Transport = "Transport",
  HealthLifeScience = "HealthLifeScience",
  LaboratoryTesting = "LaboratoryTesting",
  Chemicals = "Chemicals",
  Manufacturing = "Manufacturing",
  OilGas = "OilGas",
  ConsumerProducts = "ConsumerProducts",
  Government = "Government",
  EducationalInstitution = "EducationalInstitution",
  Food = "Food",
  Aeronautical = "Aeronautical",
  Energy = "Energy",
  Petrochemicals = "Petrochemicals",
}

/** Map industry types to labels */
export const industryTypeLabelMap: Record<AccountIndustryType, string> = {
  [AccountIndustryType.Transport]: "Transport / Logistics",
  [AccountIndustryType.HealthLifeScience]: "Health Life Science",
  [AccountIndustryType.LaboratoryTesting]: "Laboratory / Testing",
  [AccountIndustryType.Chemicals]: "Chemicals",
  [AccountIndustryType.Manufacturing]: "Manufacturing",
  [AccountIndustryType.OilGas]: "Petrochemicals / Oil / Gas",
  [AccountIndustryType.ConsumerProducts]: "Consumer Products",
  [AccountIndustryType.Government]: "Government",
  [AccountIndustryType.EducationalInstitution]: "Educational Institution",
  [AccountIndustryType.Food]: "Food / Flavourings",
  [AccountIndustryType.Aeronautical]: "Aeronautical",
  [AccountIndustryType.Energy]: "Power / Energy",
  [AccountIndustryType.Petrochemicals]: "Petrochemicals",
} as const;

/** List of available Periculum jobs */
export enum PericulumJobID {
  SyncGooglePhotos = "SyncGooglePhotos",
  DeleteTempDocs = "DeleteTempDocs",
  CloseStaleQuotes = "CloseStaleQuotes",
  SyncAuth0Logs = "SyncAuth0Logs",
  CheckPdfServiceHealthy = "CheckPdfServiceHealthy",
}

/** Map job names to labels */
export const periculumJobNameMap: Record<PericulumJobID, string> = {
  [PericulumJobID.SyncGooglePhotos]: "Sync Google Photos",
  [PericulumJobID.DeleteTempDocs]: "Delete Temp Docs",
  [PericulumJobID.CloseStaleQuotes]: "Close Stale Quotes",
  [PericulumJobID.SyncAuth0Logs]: "Sync Auth0 Authentication Logs",
  [PericulumJobID.CheckPdfServiceHealthy]: "Check PDF Service Healthy",
} as const;

/** Quote and booking source for the calculated charges */
export enum ChargeSource {
  tariff = "tariff",
  office = "office",
  shipper = "shipper",
  additional = "additional",
}

/** The possible tax code options for a charge
 * Used on a Cost of Sale
 */
export enum ChargeTaxCode {
  TAX = "TAX",
  FRE = "FRE",
  // FRE = "FRE",
  // GST = "GST",
  // TAX = "TAX",
  // VAT = "VAT",
}

export const ChargeTaxCodeLabelMap: Record<ChargeTaxCode, string> = {
  [ChargeTaxCode.TAX]: "Tax",
  [ChargeTaxCode.FRE]: "No Tax",
};

/** Possible tax type options for an office */
export enum OfficeTaxType {
  GST = "GST",
  TAX = "TAX",
  VAT = "VAT",
  None = "None",
  Other = "Other",
}

/** Parts of the booking that can be selected for cloning when cloning a booking */
export enum CloneBookingPart {
  Shipments = "Shipments",
  Pickup = "Pickup",
  Packaging = "Packaging",
  Arrival = "Arrival",
  Transport = "Transport",
  Flights = "Flights",
  Notes = "Notes",
  Charges = "Charges",
  CostOfSales = "CostOfSales",
}

/** Map a clonable booking part to a label */
export const CloneBookingPartLabelMap: Record<CloneBookingPart, string> = {
  [CloneBookingPart.Shipments]: "Shipments",
  [CloneBookingPart.Pickup]: "Pickup",
  [CloneBookingPart.Packaging]: "Packaging",
  [CloneBookingPart.Arrival]: "Arrival",
  [CloneBookingPart.Transport]: "Transport",
  [CloneBookingPart.Flights]: "Flights",
  [CloneBookingPart.Notes]: "Notes",
  [CloneBookingPart.Charges]: "Charges",
  [CloneBookingPart.CostOfSales]: "Cost of Sales",
};

/** The category of the booking e.g, how it was created */
export enum BookingCategoryType {
  agent = "agent",
  quote = "quote",
  return = "return",
  standard = "standard",
}

/** Map a booking category to a label */
export const BookingCategoryTypeLabelMap: Record<BookingCategoryType, string> = {
  [BookingCategoryType.agent]: "Agent",
  [BookingCategoryType.quote]: "Quote",
  [BookingCategoryType.return]: "Return",
  [BookingCategoryType.standard]: "Standard",
};
