// prettier-ignore
export const allScopes = [
  { value: "Account:Create",                        label: "Create accounts" },
  { value: "Account:Read",                          label: "Read accounts" },
  { value: "Account:Update",                        label: "Update accounts" },
  { value: "Account:Delete",                        label: "Delete accounts" },
  { value: "Account:Merge",                         label: "Merge accounts" },
                       
  { value: "Quote:Create",                          label: "Create quotes" },
  { value: "Quote:Read",                            label: "Read quotes" },
  { value: "Quote:Update",                          label: "Update quotes" },
  { value: "Quote:Delete",                          label: "Delete quotes" },
  { value: "Quote:Send",                            label: "Send quotes from Periculum" },
                       
  { value: "Contact:Create",                        label: "Create contacts" },
  { value: "Contact:Read",                          label: "Read contacts" },
  { value: "Contact:Update",                        label: "Update contacts" },
  { value: "Contact:Delete",                        label: "Delete contacts" },
                       
  { value: "Booking:Create",                        label: "Create bookings" },
  { value: "Booking:Read",                          label: "Read bookings" },
  { value: "Booking:Update",                        label: "Update bookings" },
  { value: "Booking:Delete",                        label: "Delete bookings" },
  { value: "Booking:Cancel",                        label: "Cancel bookings" },
                    
  { value: "Forms_Bugs:Create",                     label: "Create Periculum bugs" },
  { value: "Forms_Bugs:Read",                       label: "Read Periculum bugs" },
  { value: "Forms_Bugs:Update",                     label: "Update Periculum bugs" },
  { value: "Forms_Bugs:Delete",                     label: "Delete Periculum bugs" },
                
  { value: "Forms_Features:Create",                 label: "Create Periculum features" },
  { value: "Forms_Features:Read",                   label: "Read Periculum features" },
  { value: "Forms_Features:Update",                 label: "Update Periculum features" },
  { value: "Forms_Features:Delete",                 label: "Delete Periculum features" },
            
  { value: "Settings_Countries:Create",             label: "Create countries" },
  { value: "Settings_Countries:Read",               label: "Read countries" },
  { value: "Settings_Countries:Update",             label: "Update countries" },
            
  { value: "Settings_Portcodes:Create",             label: "Create portcodes" },
  { value: "Settings_Portcodes:Read",               label: "Read portcodes" },
  { value: "Settings_Portcodes:Update",             label: "Update portcodes" },
  { value: "Settings_Portcodes:Delete",             label: "Delete portcodes" },
            
  { value: "Settings_Airlines:Create",              label: "Create airlines" },
  { value: "Settings_Airlines:Read",                label: "Read airlines" },
  { value: "Settings_Airlines:Update",              label: "Update airlines" },
  { value: "Settings_Airlines:Delete",              label: "Delete airlines" },
            
  { value: "Settings_MAWBs:Create",                 label: "Create MAWB vouchers" },
  { value: "Settings_MAWBs:Read",                   label: "Read MAWB vouchers" },
  { value: "Settings_MAWBs:Update",                 label: "Assign MAWB vouchers on bookings" },
  { value: "Settings_MAWBs:Delete",                 label: "Delete MAWB vouchers" },
            
  { value: "Settings_Packaging:Create",             label: "Create shipment packaging options" },
  { value: "Settings_Packaging:Read",               label: "Read shipment packaging options" },
  { value: "Settings_Packaging:Update",             label: "Update shipment packaging options" },
  { value: "Settings_Packaging:Delete",             label: "Delete shipment packaging options" },
            
  { value: "Settings_Charges:Create",               label: "Create charge options" },
  { value: "Settings_Charges:Read",                 label: "Read charge options" },
  { value: "Settings_Charges:Update",               label: "Update charge options" },
  { value: "Settings_Charges:Delete",               label: "Delete charge options" },
            
  { value: "Settings_UNNumbers:Create",             label: "Create UN numbers" },
  { value: "Settings_UNNumbers:Read",               label: "Read UN numbers" },
  { value: "Settings_UNNumbers:Update",             label: "Update UN numbers" },
  { value: "Settings_UNNumbers:Delete",             label: "Delete UN numbers" },
            
  { value: "Settings_Employees:Create",             label: "Create employees" },
  { value: "Settings_Employees:Read",               label: "Read employees" },
  { value: "Settings_Employees:Update",             label: "Update employees" },
          
  { value: "Settings_CostOfSales:Create",           label: "Create cost of sales on bookings" },
  { value: "Settings_CostOfSales:Read",             label: "Read cost of sales on bookings + in settings" },
  { value: "Settings_CostOfSales:Update",           label: "Update cost of sales on bookings" },
  { value: "Settings_CostOfSales:Delete",           label: "Delete cost of sales on bookings + in settings" },
          
  { value: "Settings_Global:Update",                label: "Update global settings" },
          
  { value: "Settings_Transit:Update",               label: "Update transit zone settings" },
         
  { value: "OfficeCharges_Charges:Create",          label: "Create office charges" },
  { value: "OfficeCharges_Charges:Read",            label: "Read office charges" },
  { value: "OfficeCharges_Charges:Update",          label: "Update office charges" },
  { value: "OfficeCharges_Charges:Delete",          label: "Delete office charges" },
      
  { value: "OfficeCharges_JobTariffs:Create",       label: "Create office job tariffs" },
  { value: "OfficeCharges_JobTariffs:Read",         label: "Read office job tariffs" },
  { value: "OfficeCharges_JobTariffs:Update",       label: "Update office job tariffs" },
  { value: "OfficeCharges_JobTariffs:Delete",       label: "Delete office job tariffs" },

  { value: "OfficeCharges_PackagingTariffs:Create", label: "Create office packaging tariffs" },
  { value: "OfficeCharges_PackagingTariffs:Read",   label: "Read office packaging tariffs" },
  { value: "OfficeCharges_PackagingTariffs:Update", label: "Update office packaging tariffs" },
  { value: "OfficeCharges_PackagingTariffs:Delete", label: "Delete office packaging tariffs" },

  { value: "Report:Read",                           label: "View all reports" },
  { value: "Report:Export",                         label: "Export reports" },

  { value: "Admin_RepairBooking",                   label: "Access admin repair booking page" },
  { value: "Admin_TrackingAnalytics",               label: "Access admin tracking analytics page" },
  { value: "Admin_Activity",                        label: "Access admin activity page" },
  { value: "Admin_StandardCharges",                 label: "Access admin standard charges page" },
  { value: "Admin_OfficeConfiguration",             label: "Access admin office configuration page" },
  { value: "Admin_Regions",                         label: "Access admin regions page" },
  { value: "Admin_Jobs",                            label: "Access admin jobs page" },
  { value: "Admin_Security",                        label: "Access admin security page" },

  // misc
  { value: "ChangeOffice",                          label: "Change office within Periculum" },
  { value: "CreateBookingNote",                     label: "Create booking notes" },
  { value: "CreateQuoteNote",                       label: "Create quote notes" },
  { value: "FixedCharges",                          label: "Able to create fixed charges on quotes and bookings" },
  { value: "Profile",                               label: "Able to load your own Periculum profile" },
] as const satisfies ReadonlyArray<{ value: string; label: string }>;

export type ScopeType = (typeof allScopes)[number]["value"];

/** Map a scope value to its label */
export const scopeLabelMap = Object.fromEntries(
  allScopes.map(({ value, label }) => [value, label])
) as Record<ScopeType, string>;

/** An array of strings containing all possible resource:action + special actions */
export const allScopeOptions = allScopes.map(({ value }) => value);

/** Test if a string in format <resource>:<action> or <specialaction> is a valid scope. Note: Case sensitive */
export const isValidScope = (scope: ScopeType | string): scope is ScopeType =>
  allScopeOptions.includes(scope as ScopeType);

/** Helper function that takes an array of strings
 * and ensure it's made up of valid scopes
 */
export const getScopes = (permission: string[]): ScopeType[] =>
  permission.filter(isValidScope);

/** Get the friendly label for a scope */
export const getScopeLabel = (scope: ScopeType): string =>
  allScopes.find((s) => s.value === scope)?.label ?? "Unknown";
