import { useAuth0 } from "@auth0/auth0-react";
import * as Sentry from "@sentry/react";
import { ErrorPage, SplashScreen } from "components";
import { DocumentContextProvider } from "contexts/DocumentContext";
import { memo } from "react";
import Root from "Root";
import { useProfileQuery } from "services/graphql/generated";
import { GlobalStore, StoreCacheKey, StoreProvider } from "services/state/StoreProvider";
import ThemeProvider from "services/state/ThemeProvider";

function App() {
  const { loginWithRedirect } = useAuth0();

  // Load profile from cache if exists, otherwise load from server
  const cachedStoreJson = window.localStorage.getItem(StoreCacheKey);
  const cachedStore = cachedStoreJson
    ? (JSON.parse(cachedStoreJson) as { state?: GlobalStore; version?: number })
    : undefined;
  const cachedProfile = cachedStore?.state?.profile;

  const profileQuery = useProfileQuery({
    skip: !!cachedProfile,
  });

  if (profileQuery.error) {
    if (profileQuery.error?.message === "Login required") {
      loginWithRedirect();
      return <div>Login required, redirecting...</div>;
    } else {
      // Error loading profile
      Sentry.captureException(profileQuery.error);
      console.error(profileQuery.error);
      return <div>{profileQuery.error.message}</div>;
    }
  }

  const profile = profileQuery.data?.profile ?? cachedProfile;

  const appLoading = !profile && profileQuery.loading;

  return appLoading ? (
    <SplashScreen loadingText="Loading profile..." position="center" />
  ) : profile ? (
    <StoreProvider profile={{ ...profile, permissions: [] }}>
      <ThemeProvider>
        <DocumentContextProvider>
          <Root />
        </DocumentContextProvider>
      </ThemeProvider>
    </StoreProvider>
  ) : (
    <ErrorPage error={profileQuery.error ?? null} />
  );
}

export default memo(App);
