import {
  Card,
  CardContent,
  CardHeader,
  Container,
  createTheme,
  Grid,
  Stack,
  ThemeProvider,
  Typography,
} from "@mui/material";
import ServerErrorAnimation from "assets/animations/server-error.json";
import Lottie from "lottie-react";
import { lightTheme } from "config/themeConfig";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { backendHealthCheck } from "services/rest/requests";
import { BackButton, HomeButton } from "./buttons";
import { env } from "config";

interface ErrorPageProps {
  error: Error | null;
}

function ErrorPage(props: ErrorPageProps) {
  const [serverStatus, setServerStatus] = useState({
    time: "0",
    status: "Testing",
    uptime: "0 ms",
    serverResponse: "",
    databaseConnected: false,
  });
  const { error } = props;

  const history = useHistory();

  useEffect(() => {
    document.title = `Critical Error`;
    getServerStatus();
  }, []);

  const getServerStatus = async () => {
    const beginTest = window.performance.now();
    try {
      const result = await backendHealthCheck();
      const endTest = window.performance.now();
      setServerStatus({
        time: (endTest - beginTest).toFixed(2),
        status: "Success",
        uptime: result.health.uptime.toFixed(2) + " ms",
        serverResponse: result.health.message,
        databaseConnected: result.health.database,
      });
    } catch (error) {
      console.error(error);
      const endTest = window.performance.now();
      const errorStatus = {
        time: (endTest - beginTest).toFixed(2),
        status: "Failed",
        uptime: "0 ms",
        serverResponse: "None",
        databaseConnected: false,
      };
      setServerStatus(errorStatus);
    }
  };

  const theme = createTheme(lightTheme);

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="lg" sx={{ pt: "100px" }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h2">Critical Error</Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={3}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Card>
              <CardHeader title="DGI Express Status" />
              <CardContent>
                <Stack>
                  <Typography>{`Latency: ${serverStatus.time} ms`}</Typography>
                  <Typography>{`Uptime: ${serverStatus.uptime}`}</Typography>
                  <Typography>{`Response: ${serverStatus.serverResponse}`}</Typography>
                  <Typography>{`Database: ${
                    serverStatus.databaseConnected ? "Connected" : "Disconnected"
                  }`}</Typography>
                  <Stack direction="row" spacing={2} mt={4} justifyContent="space-around">
                    <BackButton onClick={() => history.goBack()}>Back</BackButton>
                    <HomeButton onClick={() => history.push("/")} />
                  </Stack>
                </Stack>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} sm={9}>
            <Lottie animationData={ServerErrorAnimation} style={{ height: 400 }} />
          </Grid>

          {error?.stack && env.isDev && (
            <Grid item xs={12}>
              <Card>
                <CardHeader title={error.name} subheader={error.message} />
                <CardContent>
                  <div dangerouslySetInnerHTML={{ __html: error.stack }} />
                </CardContent>
              </Card>
            </Grid>
          )}
        </Grid>
      </Container>
    </ThemeProvider>
  );
}

export default ErrorPage;
