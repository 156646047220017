import * as Sentry from "@sentry/react";
import { ErrorPage } from "components";
import { Component } from "react";

interface GlobalErrorBoundaryProps {
  children: React.ReactNode | null;
}

interface GlobalErrorBoundaryState {
  hasError: boolean;
  error: Error | null;
}

class GlobalErrorBoundary extends Component<
  GlobalErrorBoundaryProps,
  GlobalErrorBoundaryState
> {
  constructor(props: GlobalErrorBoundaryProps) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
    };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error, info: any) {
    Sentry.withScope((scope) => {
      scope.setExtras(info);
      this.setState({
        hasError: true,
        error,
      });
    });
  }

  render() {
    if (this.state.hasError) {
      return (
        <Sentry.ErrorBoundary
          fallback={<ErrorPage error={this.state.error} />}
          showDialog
        />
      );
    }
    return this.props.children;
  }
}

export default GlobalErrorBoundary;
