import packageInfo from "../../package.json";
import { env } from "config";

interface EnvironmentConfig {
  APP_VERSION: string;
  DGI_EXPRESS_API_VERSION: string;
  API_URL: string;
  SUBSCRIPTION_URL: string,
  FEATURES: {
    /** Option to request a signature when delivering a package */
    POD_SIGN_DEVICE: "mobile" | "desktop" | "all";
    /** Frontend file upload limitations */
    FILE_UPLOADS: {
      MAX_FILE_SIZE_MB: number;
      UPLOAD_MAX_FILE_COUNT: number;
    };
  };
  SERVICES: {
    SENTRY: {
      DSN: string;
    };
    AUTH0: {
      DOMAIN: string;
      PERICULUM: {
        CLIENT_ID: string;
        AUDIENCE: string;
      };
    };
  };
}

export const config: EnvironmentConfig = {
  APP_VERSION: packageInfo.version,
  DGI_EXPRESS_API_VERSION: "v1",
  API_URL: env.VITE_API_URL,
  SUBSCRIPTION_URL: env.VITE_SUBSCRIPTION_URL,

  FEATURES: {
    POD_SIGN_DEVICE: "mobile",
    FILE_UPLOADS: {
      MAX_FILE_SIZE_MB: 30,
      UPLOAD_MAX_FILE_COUNT: 10,
    },
  },
  SERVICES: {
    AUTH0: {
      DOMAIN: env.VITE_AUTH0_DOMAIN,
      PERICULUM: {
        CLIENT_ID: env.VITE_AUTH0_CLIENT_ID,
        AUDIENCE: env.VITE_AUTH0_AUDIENCE,
      },
    },
    SENTRY: {
      DSN: env.VITE_SENTRY_DSN,
    },
  },
};
