import { NetworkStatus } from "@apollo/client";
import { format, formatDistanceToNow, subSeconds } from "date-fns";
import isEqual from "react-fast-compare";

// Create valid topics and assign consistent colours
type LogTopic = "IndexedDB";

const LogTopicMap: Record<LogTopic, string> = {
  IndexedDB: "blue",
};

/** Reusable logger for logs in production */
export const log = (topic: LogTopic, message: string): void => {
  import.meta.env.DEV &&
    console.info(`%c[${topic}] ${message}`, `color:${LogTopicMap[topic]}`);
};

// take an array of objects, an array of strings as a priority list and a field to sort the objects by
export function customSort(arr: any[], sortOrder: string[], sortField: string) {
  // add low priority option to sort order
  const newSortOrder = [...sortOrder, "other"];
  // ensure all items have some level of priority
  const arrFix = arr.map((item) => ({
    ...item,
    sortStatus: newSortOrder.includes(item[sortField])
      ? item[sortField as keyof typeof item]
      : "other",
  }));
  return arrFix.sort((a, b) => {
    return newSortOrder.indexOf(a.sortStatus) - newSortOrder.indexOf(b.sortStatus);
  });
}

export function generateManifestOption(
  flightDate: string,
  agentid: string | number,
  origin: string,
  destination: string
) {
  const dateString = format(new Date(flightDate), "dd-LLL-yyyy");
  const newManifest = `${agentid}${origin}${destination}${dateString}`;
  return newManifest;
}

// Start url with https if no protocol specified
export function formatUrl(url: string) {
  if (url.length === 0) {
    return "";
  }
  if (url.startsWith("http") || url.startsWith("https")) {
    return url;
  } else {
    return `https://${url}`;
  }
}

export const htmlDecode = (input: string) => {
  const doc = new DOMParser().parseFromString(input, "text/html");
  return doc.documentElement.textContent ?? "";
};

export function formatDateTimeDistance(
  dateStringISO: Date | string | undefined | null,
  formatString: string
) {
  try {
    if (!dateStringISO) {
      throw new Error("Invalid date");
    }
    // Ensure it's a date object.
    // Also subtract 3 seconds to prevent distanceToNow being in the future
    const dateObject = subSeconds(
      dateStringISO instanceof Date ? dateStringISO : new Date(dateStringISO),
      3
    );
    const formattedDate = format(dateObject, formatString);
    const distance = formatDistanceToNow(dateObject, {
      addSuffix: true,
    });
    return [formattedDate, distance];
  } catch (error) {
    return ["", ""];
  }
}

export function formatIfExists(
  dateStringISO: string | undefined | null,
  formatString: string
) {
  try {
    if (!dateStringISO) {
      throw new Error("Invalid date");
    }
    const dateObject = new Date(dateStringISO);
    const usersOffset = dateObject.getTimezoneOffset() / 60;
    dateObject.setHours(dateObject.getHours() + usersOffset);

    const formattedDate = format(dateObject, formatString);
    return formattedDate;
  } catch (error) {
    return "";
  }
}

// Ensure the variable is a property of an object
export function getProperty<T, K extends keyof T>(object: T, propertyName: K): T[K] {
  return object[propertyName]; // o[propertyName] is of type T[K]
}

export type CountryFlagHeightOption =
  | "20"
  | "40"
  | "80"
  | "160"
  | "320"
  | "640"
  | "1280"
  | "2560";
/**
 * Get a URL that displays a countries flag
 * @param countrycode A 2 letter country code e.g. AU
 * @param width Width of the flag image
 * @returns A URL that displays a countries flag
 */
export const loadCountryFlag = (
  countrycode: string,
  width: CountryFlagHeightOption
): string =>
  `https://flagcdn.com${width ? `/w${width}` : ""}/${countrycode.toLowerCase()}.png`;

export function clipString(string: string, maxLength: number): string {
  return string.length > maxLength ? string.slice(0, maxLength) + "..." : string;
}

/** Take an ISO date and convert it to a Date object without
 *  adding the users local timezone offset. */
export const noLocalTimezone = (date: string | null | undefined) => {
  const dateObject = date ? new Date(date) : new Date();
  const usersOffset = dateObject.getTimezoneOffset() / 60;
  dateObject.setHours(dateObject.getHours() + usersOffset);
  return dateObject;
};

export const newDateStringNoTimezone = () =>
  format(new Date(), "yyyy-MM-dd'T'HH:mm:00.000'Z'");

/**
 * Compare two things and return undefined if they're equal
 * ! A very specific use case for minimizing data sent over apollo client
 * @param current The current value
 * @param previous The previous value
 * @returns The current value (first arg) if they're not equal, undefined if they're equal
 */
export const sendIfChanged = <TValue>(
  current: TValue,
  previous: unknown
): undefined | TValue => (isEqual(current, previous) ? undefined : current);

/** Map an Apollo network status to a readable label */
export const apolloNetworkStatus: Record<NetworkStatus, string> = {
  1: "loading",
  2: "setVariables",
  3: "fetchMore",
  4: "refetch",
  6: "poll",
  7: "ready",
  8: "error",
};
