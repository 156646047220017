import { ChevronLeft, Menu } from "@mui/icons-material";
import {
  AppBar,
  Avatar,
  Box,
  ClickAwayListener,
  Divider,
  Drawer,
  Grid,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useIsMobile } from "hooks";
import ms from "ms";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import LeftMenu from "screens/GlobalNav/LeftMenu";
import NavIconPane from "screens/GlobalNav/NavIconPane";
import { useUnreadEmailCountQuery } from "services/graphql/generated";
import useStore from "services/state/StoreProvider";

const drawerWidth = 270;

interface NavigationProps {
  children: React.ReactNode;
}

function Navigation(props: NavigationProps) {
  const [open, setOpen] = useState(false);
  const theme = useTheme();

  const pollEmails = useStore((state) => state.pollEmails);
  const togglePollEmails = useStore((state) => state.togglePollEmails);
  const profile = useStore((state) => state.profile);

  const history = useHistory();
  const isMobile = useIsMobile();

  let toolbarText;
  if (isMobile) {
    toolbarText = "DGI Global";
  } else {
    toolbarText = profile.periculumOffice?.office.client ?? "No Office";
  }

  const { data } = useUnreadEmailCountQuery({
    pollInterval: ms("10m"),
  });
  const emailCount = data?.unreadEmailCount ?? 0;

  // If user has no email licence, don't poll for emails
  useEffect(() => {
    if (emailCount === -1 && pollEmails) {
      togglePollEmails();
    }
  }, [emailCount, pollEmails, togglePollEmails]);

  function handleClickOutside() {
    open && setOpen(false);
  }

  function handleNavigationClicked() {
    setOpen(false);
  }
  function handleOpenNav() {
    setOpen(true);
  }

  function handleTitleClicked() {
    history.push("/");
  }

  return (
    <Box display="flex">
      <AppBar
        sx={{
          transition: theme.transitions.create(["margin", "width", "all"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
          "@media print": {
            display: "none",
          },

          ...(open && {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
            transition: theme.transitions.create(["margin", "width"], {
              easing: theme.transitions.easing.easeOut,
              duration: theme.transitions.duration.enteringScreen,
            }),
          }),
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            onClick={handleOpenNav}
            edge="start"
            size="large"
            sx={{
              display: open ? "none" : "",
              mr: 2,
              "&:hover": {
                color: theme.palette.grey[300],
              },
            }}
          >
            <Menu />
          </IconButton>
          <Typography
            variant="h6"
            noWrap
            sx={{
              cursor: "pointer",
              "&:hover": {
                color: theme.palette.grey[300],
              },
            }}
            onClick={handleTitleClicked}
            title="Home"
          >
            {toolbarText}
          </Typography>
          <Box display="flex" flexGrow={1} justifyContent="flex-end">
            <NavIconPane emailCount={pollEmails ? emailCount : 0} />
          </Box>
        </Toolbar>
      </AppBar>

      <ClickAwayListener
        mouseEvent="onMouseDown"
        touchEvent="onTouchStart"
        onClickAway={handleClickOutside}
      >
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
          }}
          variant="persistent"
          anchor="left"
          open={open}
          PaperProps={{
            style: {
              width: drawerWidth,
            },
          }}
        >
          <Box
            display="flex"
            alignItems="center"
            px={1}
            justifyContent="flex-end"
            sx={{
              wordBreak: "break-all",
            }}
          >
            <IconButton
              edge="end"
              color="inherit"
              onClick={() => {
                setOpen(false);
                history.push("/profile");
              }}
              size="large"
            >
              <Avatar
                alt={profile.employee.fullname}
                src={profile.employee.picture ?? undefined}
              />
            </IconButton>
            <Grid container pl={2}>
              <Grid item xs={12}>
                <Typography variant="subtitle1">{profile.employee.fullname}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle2">
                  {profile.periculumOffice?.office.client ?? "No Office"}
                </Typography>
              </Grid>
            </Grid>
            <IconButton onClick={() => setOpen(false)} size="large">
              <ChevronLeft />
            </IconButton>
          </Box>
          <Divider />
          <LeftMenu closeNav={handleNavigationClicked} />
        </Drawer>
      </ClickAwayListener>
      <main
        style={{
          flexGrow: 1,
          maxWidth: "100%",
          transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
          marginLeft: -drawerWidth,

          ...(open && {
            transition: theme.transitions.create("margin", {
              easing: theme.transitions.easing.easeOut,
              duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: 0,
          }),
        }}
      >
        {props.children}
      </main>
    </Box>
  );
}

export default Navigation;
