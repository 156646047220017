import { getErrorMessage } from "@dgi/utils";
import * as Sentry from "@sentry/react";
import { subYears } from "date-fns";
import { ProfileQueryResult, useProfileLazyQuery } from "services/graphql/generated";
import { create, StoreApi } from "zustand";
import createContext from "zustand/context";
import { createJSONStorage, persist } from "zustand/middleware";
import ProfileProvider from "./ProfileProvider";

export type GlobalThemeOption = "light" | "dark";

export type EmployeeProfile = NonNullable<
  NonNullable<ProfileQueryResult["data"]>["profile"]
>;

type State = {
  _hasHydrated: boolean;
  profile: EmployeeProfile;
  theme: GlobalThemeOption;
  pollEmails: boolean;
  lastAccountsRefresh: string;
  rotatingHome: boolean;
  secondsPerTab: number;
};
type Actions = {
  updateProfile: (profile: EmployeeProfile) => void;
  refetchProfile: () => Promise<void>;
  toggleTheme: () => void;
  togglePollEmails: () => void;
  /** Store date of last accounts lookup */
  setLastAccountsRefresh: (date: Date) => void;
  /** When in fullscreen on the home page, should the app rotate through tabs */
  toggleRotatingHome: () => void;
  setSecondsPerTab: (seconds: number) => void;
  reset: () => void;
};

export type GlobalStore = State & Actions;

const { Provider, useStore, useStoreApi } = createContext<StoreApi<GlobalStore>>();

export const storeAPI = useStoreApi;

const defaultLastAccountsRefresh = subYears(new Date(), 100).toISOString();

interface StoreProviderProps {
  profile: EmployeeProfile;
  children: React.ReactNode;
}

export const StoreCacheKey = "periculum-store";

/** Bump this when zustand store schema changes and implement a
 *  migration strategy for the new version
 */
const STORE_SCHEMA_VERSION = 1;

export function StoreProvider(props: StoreProviderProps) {
  const { profile } = props;

  const initialState: State = {
    _hasHydrated: false,
    profile,
    lastAccountsRefresh: defaultLastAccountsRefresh,
    pollEmails: true,
    rotatingHome: false,
    secondsPerTab: 10,
    theme: "light",
  };

  const [fetchProfile] = useProfileLazyQuery();

  const createStore = () =>
    create<GlobalStore>()(
      persist(
        (set) => ({
          ...initialState,
          updateProfile: (profile: EmployeeProfile) =>
            set((state) => ({ ...state.profile, profile })),
          refetchProfile: async () => {
            const { data } = await fetchProfile();
            data?.profile && set((state) => ({ ...state, profile: data.profile }));
          },
          toggleTheme: () =>
            set((state) => ({ theme: state.theme === "light" ? "dark" : "light" })),
          togglePollEmails: () => set((state) => ({ pollEmails: !state.pollEmails })),
          setLastAccountsRefresh: (date) =>
            set(() => ({ lastAccountsRefresh: date.toISOString() })),
          toggleRotatingHome: () =>
            set((state) => ({ rotatingHome: !state.rotatingHome })),
          setSecondsPerTab: (seconds: number) => set(() => ({ secondsPerTab: seconds })),
          reset: () =>
            set(({ profile }) => ({
              // Exclude profile and _hasHydrated from reset
              ...initialState,
              profile,
              _hasHydrated: true,
            })),
        }),
        {
          name: StoreCacheKey,
          storage: createJSONStorage(() => localStorage),
          onRehydrateStorage: (_stateBeforeHydration) => (stateAfterHydration, error) => {
            // Set _hasHydrated to true after hydration
            if (error) {
              console.error(`Error rehydrating store: ${getErrorMessage(error)}`);
              Sentry.captureException(error);
              return;
            }
            if (stateAfterHydration) {
              stateAfterHydration._hasHydrated = true;
            }
          },
          // Busts the local cache each version bump
          version: STORE_SCHEMA_VERSION,
          migrate: (persistedState, _version) => {
            // No migrations yet
            return persistedState as GlobalStore;
          },
        }
      )
    );

  return (
    <Provider createStore={createStore}>
      <ProfileProvider>{props.children}</ProfileProvider>
    </Provider>
  );
}

export default useStore;
