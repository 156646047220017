import {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { Breadcrumb } from "types/types";

const DocumentContext = createContext<
  | {
      breadcrumbs: Breadcrumb[];
      setBreadcrumbs: Dispatch<SetStateAction<Breadcrumb[]>>;
    }
  | undefined
>(undefined);

interface DocumentContextProviderProps {
  children: React.ReactNode;
}

function DocumentContextProvider(props: DocumentContextProviderProps) {
  const [breadcrumbs, setBreadcrumbs] = useState<Breadcrumb[]>([]);

  // Set page title to last item in breadcrumbs
  useEffect(() => {
    if (breadcrumbs.length) {
      const lastBreadcrumb = breadcrumbs[breadcrumbs.length - 1];
      document.title = `Periculum | ${lastBreadcrumb.text}`;
    }
  }, [breadcrumbs]);

  const value = {
    breadcrumbs,
    setBreadcrumbs,
  };

  return (
    <DocumentContext.Provider value={value}>{props.children}</DocumentContext.Provider>
  );
}

function useDocument() {
  const context = useContext(DocumentContext);
  if (context === undefined) {
    throw new Error("useDocument must be used within a DocumentContext");
  }
  return context;
}

export { DocumentContextProvider, useDocument };
