import { AccountIndustryType, BookingType } from "@dgi/utils";
import {
  AccountBalance as GovernmentIcon,
  AccountTree as InterOfficeIcon,
  Business as AccountIcon,
  EuroSymbol as EuropeIcon,
  Flight as AeronauticalIcon,
  Flight as TransportIcon,
  FlightTakeoff as BookingsIcon,
  FlightLand as ArrivalIcon,
  House as DomesticIcon,
  Inventory2Outlined as ShipmentsIcon,
  InventoryOutlined as DetailsIcon,
  LocalGasStation as OilGasIcon,
  LocalPizza as FoodIcon,
  LocalShipping as TruckIcon,
  LocalShippingOutlined as DeliveryIcon,
  LocationCity as CityMetroIcon,
  NaturePeople as LifeScienceIcon,
  NoteAdd as ShipDecAndPackIcon,
  Opacity as ChemicalsIcon,
  People as ContactIcon,
  PhoneIphone as ConsumerIcon,
  PlaceOutlined as PickupIcon,
  PlayForWork as ImportIcon,
  Power as EnergyIcon,
  Public as InternationalIcon,
  Redeem as PackagingIcon,
  RequestQuote as QuoteIcon,
  School as EducationIcon,
  Work as WorkIcon,
  AirplaneTicket as FlightsIcon,
} from "@mui/icons-material";

// TODO: Migrate common constants to utils package and delete anything unused in this utils folder

export const EMAIL_BUTTON_COLOUR = {
  SENT: "#4caf50",
  NOT_SENT: "#CA302C",
};

export const SEARCH_CACHE_KEYS = {
  BOOKINGS_SEARCH: "BOOKINGS_SEARCH",
  QUOTES_SEARCH: "QUOTES_SEARCH",
  ACCOUNTS_SEARCH: "ACCOUNTS_SEARCH",
  CONTACTS_SEARCH: "CONTACTS_SEARCH",
};

/** Commonly used icons throughut the app */
export const AppIcons = {
  Quotes: QuoteIcon,
  Accounts: AccountIcon,
  Bookings: BookingsIcon,
  Contacts: ContactIcon,

  // Booking Type
  Domestic: DomesticIcon,
  International: InternationalIcon,
  CityMetro: CityMetroIcon,
  InterOffice: InterOfficeIcon,
  ShipDecAndPack: ShipDecAndPackIcon,
  Import: ImportIcon,
  Europe: EuropeIcon,
  Packaging: PackagingIcon,

  // Booking steps
  Details: DetailsIcon,
  Shipments: ShipmentsIcon,
  Pickup: PickupIcon,
  Arrival: ArrivalIcon,
  Transport: TransportIcon,
  Delivery: DeliveryIcon,

  // Misc
  Flights: FlightsIcon,
} as const;

// export const ALL_JOB_STATUSES = [
//   { value: BookingJobStatus.Cancelled, name: "Cancelled" },
//   { value: BookingJobStatus.Confirmed, name: "Confirmed" },
//   { value: BookingJobStatus.PickedUp, name: "Picked up" },
//   { value: BookingJobStatus.InTransit, name: "In-Transit" },
//   { value: BookingJobStatus.Arrived, name: "Arrived" },
//   { value: BookingJobStatus.Delivered, name: "Delivered" },
// ];

// export const ALL_JOB_STEPS = [
//   { value: BookingJobStep.DETAILS, name: "Details" },
//   { value: BookingJobStep.SHIPMENT_ITEMS, name: "Shipments" },
//   { value: BookingJobStep.PICKUP, name: "Pickup" },
//   { value: BookingJobStep.ARRIVAL, name: "Arrival" },
//   { value: BookingJobStep.TRANSPORT, name: "Transport" },
//   { value: BookingJobStep.DELIVERY, name: "Delivery" },
// ];

export const RouteCardIconStyle = {
  transition: "all 0.3s ease-in-out",
  height: "100px",
  width: "100%",
};

export const months: Record<number, string> = {
  1: "Jan",
  2: "Feb",
  3: "Mar",
  4: "Apr",
  5: "May",
  6: "Jun",
  7: "Jul",
  8: "Aug",
  9: "Sep",
  10: "Oct",
  11: "Nov",
  12: "Dec",
};

// Replacing the [DROPDOWNVALS] table with hardcoded values
export const REPORTING_REGIONS = ["Oceania", "Europe", "Middle East", "Americas"];

export const CHARGE_TYPE_SUGGESTIONS = [
  "Customs Duty",
  "Dry Ice",
  "Air Line Delivery",
  "Customs Inspection",
  "MultiModal Decalaration",
  "Customs Surety Bond",
  "USDA Administration",
  "Domestic Linehaul",
  "Plastic Inners",
  "Documentation",
  "Room Hire",
  "VAT",
  "Bio-pouches",
  "Data Logger",
  "Security Screening",
  "Customs Clearance and Delivery",
  "Quarantine",
  "Custom Agency",
  "AQIS",
  "CCA Fee",
  "DHL Fee",
  "Storage/ Customs Clearance & Disposal",
  "Customs VAT",
  "Insurance",
  "Airline Security",
  "Return",
  "Liquid Nitrogen",
  "Dry Shipper Sameday",
  "Shipment Return Fee",
  "Wet Ice",
  "Bank Handling Charge",
  "ITF (International Terminal Fee at cost)",
  "Cartage - Metro Area",
  "Import EQ Up to 1kg",
  "1-5 Kg",
  "> than 5 kg",
  "Attendence Fee",
  "DG Check - Air",
  "DG Check - Road",
  "Disposal",
  "Haz Labels ",
  "Labels",
  "Gst per customs entry",
  "EEC per customs entry",
  "Administration Fee",
  "Courier",
  "Poly Box",
  "Transit Carton",
  "Documentation IATA Regulations (Book)",
  "Linehaul",
  "Delivery",
  "Import Charges",
  "Export Freight Charges",
  "Foreign Charges International",
  "Domestic Air Freight",
  "International Freight",
  "Tremcards",
  "Extra UN Number",
  "Out of Area Surcharge - Domestic",
  "Out of Area Surcharge - International",
  "Fuel Surcharge Tax",
  "Temperature Logger",
  "Consultancy",
  "Class 6.2 Infectious Bottle",
  "Dry Ice Top Up",
  "Airline Handling",
  "War Risk",
  "Export Fees",
  "Clearance & Delivery",
  "Saturday Delivery",
  "Special Delivery",
  "AQIS Fee",
  "AQIS Attendance",
  "AQIS Inspection",
  "Cargo Automation",
  "GST",
  "Airline Document Fee",
  "Import Terminal Fee",
  "DG Surcharge",
  "BAF",
  "DG Declaration",
  "Port Services",
  "Destination Quarantine",
  "Delivery Order",
  "IATA CASS Freight",
  "IATA CASS Surcharges",
  "Destination Clearance and Delivery",
  "MAF Charge",
  "Credit Card Fee",
  "DG Training",
  "Decanting Fee",
  "Handling",
  "ISPS",
  "Airline Security Fee",
  "Dry Shipper Priming",
  "Gel Pack",
  "Airline Transfer",
  "Waiting Time",
  "Customs Levy",
  "Customs Clearance",
  "Delivery Charge",
  "Handover Fee",
  "Absorbancy Fee",
  "Saturday Special",
  "Agents Fee",
  "Dry Shipper Rental",
  "Clearance Charges",
  "Collection AM",
  "Special Collection",
  "HMC Duty Paid",
  "HMC VAT Paid",
  "Duty/VAT Adv Fee",
  "Quote",
  "24 Hour Emergency help line",
  "Dedicated Vechile",
  "Packaging",
  "False Collection",
  "Packaging Danvers International",
  "Trem Card",
  "Urgent DG Check Surcharge",
  "Marking & Labelling",
  "Call Out Fee",
  "Freight",
  "Labour",
  "Additional UN numbers",
  "Transport Charges",
  "Collection / Pick Up",
  "Fuel Surcharge",
  "Duties and Taxes",
  "Vermiculite",
  "Storage",
  "Collection ",
  "Packaging Delivery",
  "Cancellation Fee",
  "Documentation Fee",
  "Duties & Taxes",
  "Cargo Aircraft only - Min 45kg",
  "Out of Area Surcharge",
  "False Call",
  "Out of Hours Surcharge",
];

/** Map a BookingType to an icon */
export const BookingTypeIconMap: Record<BookingType, JSX.Element> = {
  [BookingType.Domestic]: <AppIcons.Domestic />,
  [BookingType.International]: <AppIcons.International />,
  [BookingType.CityMetro]: <AppIcons.CityMetro />,
  [BookingType.InterOffice]: <AppIcons.InterOffice />,
  [BookingType.ShipDecAndPack]: <AppIcons.ShipDecAndPack />,
  [BookingType.Import]: <AppIcons.Import />,
  [BookingType.Europe]: <AppIcons.Europe />,
  [BookingType.Packaging]: <AppIcons.Packaging />,
};

/** Map an Account Industry type to an icon */
export const IndustryIconMap: Record<AccountIndustryType, JSX.Element> = {
  [AccountIndustryType.LaboratoryTesting]: <ChemicalsIcon fontSize="small" />,
  [AccountIndustryType.Petrochemicals]: <ChemicalsIcon fontSize="small" />,
  [AccountIndustryType.Chemicals]: <ChemicalsIcon fontSize="small" />,
  [AccountIndustryType.EducationalInstitution]: <EducationIcon fontSize="small" />,
  [AccountIndustryType.ConsumerProducts]: <ConsumerIcon fontSize="small" />,
  [AccountIndustryType.Transport]: <TruckIcon fontSize="small" />,
  [AccountIndustryType.HealthLifeScience]: <LifeScienceIcon fontSize="small" />,
  [AccountIndustryType.Food]: <FoodIcon fontSize="small" />,
  [AccountIndustryType.Energy]: <EnergyIcon fontSize="small" />,
  [AccountIndustryType.OilGas]: <OilGasIcon fontSize="small" />,
  [AccountIndustryType.Aeronautical]: <AeronauticalIcon fontSize="small" />,
  [AccountIndustryType.Government]: <GovernmentIcon fontSize="small" />,
  [AccountIndustryType.Manufacturing]: <WorkIcon fontSize="small" />,
};
