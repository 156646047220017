import { Fade } from "@mui/material";
import { styled } from "@mui/material/styles";
import Table, { TableProps } from "@mui/material/Table";
import TableCell, { TableCellProps } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow, { TableRowProps } from "@mui/material/TableRow";
import { useMemo } from "react";
import { SortDirection } from "services/graphql/generated";
import TableBodyHelper, { LoadingOptions } from "./TableBodyHelper";
import TableHeadings, { NoWrapTableCell, TableHeadColumn } from "./TableHeadings";
import accessValue, { ValueAccessor } from "./tableUtils";

/** Type for row data objects */
type RowData = { [key: string]: any };

/** Type for react component that renders a cell */
export type CellOverrideComponent<TRow extends RowData> = React.ComponentType<
  TableCellProps & { row: TRow; column: TableHelperColumn<TRow> }
>;

/** Type for react component that renders a row */
export type RowOverrideComponent<TRow extends RowData> = React.ComponentType<
  TableRowProps & { row: TRow }
>;

/** Type for a function that can modify row properties */
export type RowProps<TRow extends RowData> = (
  row: TRow,
  index: number
) => Partial<TableRowProps> | undefined;

/** Styled component that adds a pointer cursor when onClick is provided */
const ClickableTableRow = styled(TableRow)`
  cursor: ${({ onClick }) => (onClick ? "pointer" : "auto")};
`;

/** Type for column alignment */
type Alignment = "left" | "center" | "right";

/** Empty array for use as default */
const noRows: any[] = [];

export type TableHelperColumn<TRow extends RowData> = {
  /** the column title */
  label: string;
  /** if true, the column is sortable */
  sortable?: boolean;
  /** the width of the column */
  width?: string;
  /** the alignment of the column */
  align?: Alignment;
  /** the alignment of the column header */
  alignHeader?: "left" | "center" | "right";
  /** the alignment of the column body */
  alignBody?: "left" | "center" | "right";
  /** if true, the text in the column will not wrap */
  noWrap?: boolean;
  /** a component to render the cell */
  CellComponent?: CellOverrideComponent<TRow>;
  /** if true, the column is a row header */
  isRowHeader?: boolean;
  /** if true, display the column on hover */
  showOnHover?: boolean;
  /** if true, hide the column */
  hidden?: boolean | ((row: TRow) => boolean);
  /** the children of the column */
  children?: JSX.Element | JSX.Element[] | null;
} & (
  | { field: ValueAccessor<TRow> }
  | { render: (row: TRow, index: number) => React.ReactNode }
);

export type TableRowClickHandler<TRow extends RowData> = (
  row: TRow,
  index: number
) => void;

export interface TableHelperProps<TRow extends RowData> extends TableProps {
  /** The array of columns that will be displayed on the table. */
  columns: TableHelperColumn<TRow>[];
  /** The array of rows that will be displayed on the table. */
  rows?: TRow[];
  /** Highlight the header of the table. */
  highlightHeader?: boolean;
  /** Show a loading state on the table. */
  loading?: boolean;
  /** Options to customize the loading state. */
  loadingOptions?: LoadingOptions;
  /** A JSX element to be displayed at the bottom of the table. */
  tableFooter?: JSX.Element;
  /** Props to be passed in to each row of the table. */
  rowProps?: RowProps<TRow> | undefined;
  /** Override the rendering of each row by passing in a component that can access props.row */
  RowComponent?: RowOverrideComponent<TRow>;
  /** The name of the field that will be used to sort the table. */
  sortBy?: string;
  /** The direction that the table will be sorted by. */
  sortDirection?: SortDirection;
  /** A callback that will be called when a column header is clicked. */
  handleSortPressed?: (field: string) => void;
  /** Show an error state on the table. */
  error?: boolean;
  /** The error message to be displayed on the table. */
  errorMessage?: string | JSX.Element;
  /** The message to be displayed when the table is empty. */
  emptyMessage?: string | JSX.Element;
  /** A callback that will be called when a row is clicked. */
  onClickRow?: TableRowClickHandler<TRow>;
}

function TableHelper<TRow extends RowData>(props: TableHelperProps<TRow>) {
  const {
    columns,
    rows = noRows,
    loading = false,
    RowComponent,
    highlightHeader = false,
    loadingOptions,
    children,
    tableFooter,
    rowProps: rowPropsOverride,
    sortBy,
    sortDirection,
    handleSortPressed,
    error = false,
    errorMessage,
    emptyMessage,
    onClickRow,
    ...tableProps
  } = props;

  const headingColumns = useMemo<TableHeadColumn[]>(() => {
    return columns.map((column) => ({
      label: column.label,
      field:
        "field" in column && typeof column.field === "string" ? column.field : undefined,
      sortable: column.sortable,
      width: column.width,
      align: column.alignHeader ?? column.align,
      isRowHeader: column.isRowHeader,
    }));
  }, [columns]);

  return (
    <TableContainer>
      <Table stickyHeader  {...tableProps}>
        <TableHeadings
          headings={headingColumns}
          orderBy={sortBy}
          orderDirection={sortDirection}
          handleSortPressed={handleSortPressed}
          highlight={highlightHeader}
        />
        <TableBodyHelper
          columnCount={columns.length}
          empty={rows.length === 0}
          emptyMessage={emptyMessage}
          error={error}
          errorMessage={errorMessage}
          loading={loading}
          loadingOptions={loadingOptions}
        >
          {rows.map((row, rowIndex) => {
            const rowContent = columns.map((column, columnIndex) => {
              const { CellComponent } = column;
              const cellContent =
                "render" in column
                  ? column.render(row, rowIndex)
                  : accessValue(row, column.field);

              const isCellHidden =
                // Hide if 'hidden' is a boolean and set to true
                ("hidden" in column &&
                  typeof column.hidden === "boolean" &&
                  column.hidden === true) ||
                // Hide if 'hidden' is a function and returns true
                ("hidden" in column &&
                  typeof column.hidden === "function" &&
                  column.hidden(row) === true);

              // Create a variable for the cell styling
              const commonProps = {
                key: columnIndex,
                align: column.alignBody ?? column.align,
                component: column.isRowHeader ? "th" : undefined,
                scope: column.isRowHeader ? "row" : undefined,
                className: column.showOnHover ? "show-on-hover" : undefined,
                sx: {
                  opacity: column.showOnHover ? 0 : undefined,
                  visibility: isCellHidden ? "hidden" : undefined,
                },
              } as const;

              // Create a variable for the table cell component
              if (CellComponent) {
                return (
                  <CellComponent {...commonProps} row={row} column={column}>
                    {cellContent}
                  </CellComponent>
                );
              }

              // Create a variable for the table cell component
              const TableCellComponent = column.noWrap ? NoWrapTableCell : TableCell;

              return (
                <TableCellComponent {...commonProps}>{cellContent}</TableCellComponent>
              );
            });

            const rowPropOverrides = rowPropsOverride
              ? rowPropsOverride(row, rowIndex)
              : {};

            // Create an object with the row properties that will be passed to the row component.
            const rowProps: TableRowProps = {
              ...rowPropOverrides,
              hover: !!onClickRow || !!rowPropOverrides?.hover,
              onClick: onClickRow
                ? () => onClickRow(row, rowIndex)
                : rowPropOverrides?.onClick,
              sx: {
                ...rowPropOverrides?.sx,
                "&:hover .show-on-hover": {
                  opacity: 1,
                },
              },
            };

            // Generate a unique key for the row component.
            const key = row.id ?? `${row.label}:${rowIndex}`;

            // If the user passed a custom row component, render it.
            if (RowComponent) {
              return (
                <Fade key={key} in>
                  <RowComponent {...rowProps} row={row}>
                    {rowContent}
                  </RowComponent>
                </Fade>
              );
            }

            // 5. Otherwise, render a default row component.
            return (
              <Fade key={key} in>
                <ClickableTableRow {...rowProps}>{rowContent}</ClickableTableRow>
              </Fade>
            );
          })}
        </TableBodyHelper>
        {tableFooter}
      </Table>
      {children}
    </TableContainer>
  );
}

export default TableHelper;
