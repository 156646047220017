import { createTheme, ThemeProvider as MuiThemeProvider } from "@mui/material";
import { darkTheme, lightTheme } from "config/themeConfig";
import useStore from "services/state/StoreProvider";

interface ThemeProviderProps {
  children: React.ReactNode | React.ReactNode[];
}

function ThemeProvider(props: ThemeProviderProps) {
  const theme = useStore((state) => state.theme);
  const muiTheme = createTheme(theme === "light" ? lightTheme : darkTheme);

  return <MuiThemeProvider theme={muiTheme}>{props.children}</MuiThemeProvider>;
}

export default ThemeProvider;
