import { useAuth0 } from "@auth0/auth0-react";
import { LockOutlined as LockOutlinedIcon } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  createTheme,
  Grid,
  Link,
  Paper,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { lightTheme } from "config/themeConfig";

const publicTheme = createTheme(lightTheme);

function Login() {
  const { loginWithRedirect } = useAuth0();

  return (
    <ThemeProvider theme={publicTheme}>
      <Grid
        container
        component="main"
        sx={{
          height: "100vh",
        }}
      >
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: "url(https://source.unsplash.com/collection/2603566)",
            backgroundRepeat: "no-repeat",
            backgroundColor: (theme) =>
              theme.palette.mode === "dark"
                ? theme.palette.grey[900]
                : theme.palette.grey[50],
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            style={{
              padding: "50px",
              marginTop: "40px",
            }}
          >
            <Avatar
              sx={{
                m: 1,
                backgroundColor: "secondary.main",
              }}
            >
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Periculum Login
            </Typography>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              id="login-periculum"
              sx={{
                margin: "20px",
              }}
              onClick={() =>
                loginWithRedirect({
                  appState: {
                    returnTo: window.location.pathname,
                  },
                })
              }
            >
              Sign In
            </Button>
            <Box mt={5}>
              <Typography  color="textSecondary" align="center">
                {"Copyright © "}
                <Link color="inherit" href="https://dgiglobal.com">
                  DGI GLOBAL PTY LTD
                </Link>
                {` 1999 - ${new Date().getFullYear()}.`}
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}

export default Login;
