import { ReactComponent as PdfIcon } from "assets/icons/doctypes/pdf.svg";
import { ReactComponent as DocIcon } from "assets/icons/doctypes/doc.svg";
import { ReactComponent as JpgIcon } from "assets/icons/doctypes/jpg.svg";
import { ReactComponent as PngIcon } from "assets/icons/doctypes/png.svg";
import { ReactComponent as PptIcon } from "assets/icons/doctypes/ppt.svg";
import { ReactComponent as TxtIcon } from "assets/icons/doctypes/txt.svg";
import { ReactComponent as XlsIcon } from "assets/icons/doctypes/xls.svg";
import { ReactComponent as ZipIcon } from "assets/icons/doctypes/zip.svg";
import { memo } from "react";

interface DocIconProps {
  filename: string;
  width?: number;
  height?: number;
}

function DocumentIcon(props: DocIconProps) {
  const { filename, width = 40, height = 40 } = props;
  const ext = filename.split(".")[1];
  switch (ext.toLowerCase()) {
    case "pdf":
      return <PdfIcon width={width} height={height} />;
    case "doc":
    case "docx":
      return <DocIcon width={width} height={height} />;
    case "jpg":
    case "jpeg":
      return <JpgIcon width={width} height={height} />;
    case "png":
      return <PngIcon width={width} height={height} />;
    case "zip":
      return <ZipIcon width={width} height={height} />;
    case "txt":
      return <TxtIcon width={width} height={height} />;
    case "xls":
    case "xlsx":
    case "csv":
      return <XlsIcon width={width} height={height} />;
    case "ppt":
    case "pptx":
      return <PptIcon width={width} height={height} />;
    default:
      return <PdfIcon width={width} height={height} />;
  }
}

export default memo(DocumentIcon);
