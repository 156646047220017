import { Alert, Skeleton, TableBody, TableCell, TableRow } from "@mui/material";
import { memo } from "react";
import isEqual from "react-fast-compare";

export interface LoadingOptions {
  count?: number;
  height?: number;
  roundedBorders?: boolean;
}

interface TableBodyHelperProps {
  columnCount: number;
  loading?: boolean;
  loadingOptions?: { count?: number; height?: number; roundedBorders?: boolean };
  error?: boolean;
  errorMessage?: string | JSX.Element;
  empty?: boolean;
  emptyMessage?: string | JSX.Element;
  children: JSX.Element | JSX.Element[] | null;
}

function TableBodyHelper(props: TableBodyHelperProps): JSX.Element {
  const {
    columnCount,
    loading = false,
    loadingOptions = { height: 20, count: 10, roundedBorders: false },
    error = false,
    errorMessage = "Error loading items",
    empty = false,
    emptyMessage = "No items found",
    ...otherProps
  } = props;

  if (loading) {
    const skeletonCount = loadingOptions.count ?? 10;
    return (
      <TableBody {...otherProps}>
        {Array.from({ length: skeletonCount ?? 10 }).map((_, rowIndex) => (
          <TableRow key={rowIndex}>
            {Array.from({ length: columnCount }).map((_, cellIndex) => (
              <TableCell key={cellIndex}>
                <Skeleton height={loadingOptions.height} />
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    );
  }

  if (error) {
    return (
      <TableBody {...otherProps}>
        <TableRow>
          <TableCell colSpan={columnCount}>
            <Alert severity="error">{errorMessage}</Alert>
          </TableCell>
        </TableRow>
      </TableBody>
    );
  }

  if (empty) {
    return (
      <TableBody {...otherProps}>
        <TableRow>
          <TableCell colSpan={columnCount} align="center">
            {emptyMessage}
          </TableCell>
        </TableRow>
      </TableBody>
    );
  }

  return <TableBody {...otherProps}>{props.children}</TableBody>;
}

export default memo(TableBodyHelper, isEqual);
