import { getScopes, ScopeType } from "@dgi/utils";
import {
  AccountBox as AccountBoxIcon,
  Add as AddIcon,
  AdminPanelSettings as AdminIcon,
  Home as HomeIcon,
  InsertChart,
  ListAlt as ListAltIcon,
  MonetizationOn as ChargesIcon,
  SettingsOutlined as SettingsIcon,
} from "@mui/icons-material";
import {
  alpha,
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Stack,
  Typography,
} from "@mui/material";
import DGI_logo from "assets/images/dgi_logo.png";
import DGI_logo_dark from "assets/images/dgi_logo_darktheme.png";
import { Link } from "components";
import { config } from "config";
import { Link as RouterLink, useLocation } from "react-router-dom";
import useStore from "services/state/StoreProvider";
import { AppIcons } from "utils/constants";

export enum MenuItemType {
  Heading,
  Item,
  Divider,
}
interface LeftMenuItem {
  label: string;
  icon?: React.ReactElement;
  path: string;
  section: MenuSectionTitle;
  permissions?: {
    /** Must have all specified permissions to access page */
    matchAll?: ScopeType[];
    /** Must have just 1 of these permissions to access page */
    matchAny?: ScopeType[];
  };
}

enum MenuSectionTitle {
  General = "General",
  Operations = "Operations",
  Finance = "Finance",
  Settings = "Settings",
}

const MenuItems: LeftMenuItem[] = [
  {
    label: "New Booking",
    icon: <AddIcon />,
    path: "/bookings/new",
    section: MenuSectionTitle.General,
    permissions: {
      matchAll: ["Booking:Read", "Booking:Create", "Account:Read"],
    },
  },
  {
    label: "Home",
    icon: <HomeIcon />,
    path: "/",
    section: MenuSectionTitle.General,
  },
  {
    label: "Profile",
    icon: <AccountBoxIcon />,
    path: "/profile",
    section: MenuSectionTitle.General,
  },
  {
    label: "Forms",
    icon: <ListAltIcon />,
    path: "/forms",
    section: MenuSectionTitle.General,
    permissions: {
      matchAny: ["Forms_Bugs:Read", "Forms_Features:Read"],
    },
  },
  {
    label: "Quotes",
    icon: <AppIcons.Quotes />,
    path: "/quotes",
    section: MenuSectionTitle.Operations,
    permissions: {
      matchAll: ["Quote:Read", "Account:Read"],
    },
  },
  {
    label: "Bookings",
    icon: <AppIcons.Bookings />,
    path: "/bookings",
    section: MenuSectionTitle.Operations,
    permissions: {
      matchAll: ["Booking:Read", "Account:Read"],
    },
  },
  {
    label: "Accounts",
    icon: <AppIcons.Accounts />,
    path: "/accounts",
    section: MenuSectionTitle.Operations,
    permissions: {
      matchAll: ["Account:Read"],
    },
  },
  {
    label: "Contacts",
    icon: <AppIcons.Contacts />,
    path: "/contacts",
    section: MenuSectionTitle.Operations,
    permissions: {
      matchAll: ["Contact:Read"],
    },
  },
  {
    label: "Reporting",
    icon: <InsertChart />,
    path: "/reporting",
    section: MenuSectionTitle.Finance,
    permissions: {
      matchAll: ["Report:Read"],
    },
  },
  {
    label: "Data Management",
    icon: <SettingsIcon />,
    path: "/settings",
    section: MenuSectionTitle.Settings,
  },
  {
    label: "Office Charges",
    icon: <ChargesIcon />,
    path: "/charges",
    section: MenuSectionTitle.Settings,
    permissions: {
      matchAny: [
        "OfficeCharges_Charges:Read",
        "OfficeCharges_JobTariffs:Read",
        "OfficeCharges_PackagingTariffs:Read",
      ],
    },
  },
  {
    label: "Admin",
    icon: <AdminIcon />,
    path: "/admin",
    section: MenuSectionTitle.Settings,
    permissions: {
      matchAny: [
        "Admin_Activity",
        "Admin_Jobs",
        "Admin_Regions",
        "Admin_RepairBooking",
        "Admin_Security",
        "Admin_StandardCharges",
        "Admin_TrackingAnalytics",
      ],
    },
  },
];

interface LeftMenuProps {
  closeNav: () => void;
}

function LeftMenu(props: LeftMenuProps) {
  const profile = useStore((state) => state.profile);
  const currentAppTheme = useStore((state) => state.theme);
  const location = useLocation();
  const currentPath = location.pathname;

  const menuSections: Record<MenuSectionTitle, LeftMenuItem[]> = {
    [MenuSectionTitle.General]: [],
    [MenuSectionTitle.Operations]: [],
    [MenuSectionTitle.Finance]: [],
    [MenuSectionTitle.Settings]: [],
  };

  const employeePermissions = getScopes(profile.permissions.map((p) => p.name));

  MenuItems.forEach((item) => {
    // Check if user has all permissions required to render menu item
    if (
      item.permissions?.matchAll &&
      !item.permissions.matchAll.every((permission) =>
        employeePermissions.includes(permission)
      )
    ) {
      return;
    }

    // Ensure they have at least one of the permissions required to render menu item
    if (
      !item.permissions?.matchAny ||
      item.permissions.matchAny.some((permission) =>
        employeePermissions.includes(permission)
      )
    ) {
      menuSections[item.section].push(item);
    }
  });

  const handleMenuItemPressed = () => props.closeNav();

  return (
    <Stack height="100%" pb={2}>
      <List dense style={{ flexGrow: 1 }}>
        {Object.entries(menuSections).map(([section, items]) => {
          // Dont render an empty section with no items
          if (!items.length) return null;

          return (
            <div key={section}>
              {/* Dont render the 'General' title */}
              {section !== MenuSectionTitle.General ? (
                <ListSubheader>
                  <Typography variant="overline">{section}</Typography>
                </ListSubheader>
              ) : null}

              {items.map(({ path, label, icon }) => {
                const isMenuItemSelected = isMenuItemOpen(currentPath, path);
                return (
                  <ListItemButton
                    key={label}
                    // selected={isMenuItemSelected}
                    component={RouterLink}
                    to={path}
                    sx={{
                      // Apply selection styles if menu item is selected
                      ...(isMenuItemSelected && {
                        borderRight: (theme) =>
                          `2px solid ${theme.palette.secondary.main}`,
                        color: (theme) => theme.palette.secondary.main,
                        bgcolor: (theme) => alpha(theme.palette.secondary.main, 0.1),
                      }),
                      "&:hover": {
                        bgcolor: (theme) => alpha(theme.palette.secondary.main, 0.2),
                      },
                    }}
                    onClick={handleMenuItemPressed}
                  >
                    <ListItemIcon
                      sx={{
                        ...(isMenuItemSelected && {
                          color: (theme) => theme.palette.secondary.main,
                        }),
                      }}
                    >
                      {icon}
                    </ListItemIcon>
                    <ListItemText primary={label} />
                  </ListItemButton>
                );
              })}
              <Divider />
            </div>
          );
        })}
      </List>
      <Stack alignItems="center">
        <img
          src={currentAppTheme === "light" ? DGI_logo : DGI_logo_dark}
          alt="company logo"
          width="50%"
        />
        <Typography variant="h6">Periculum</Typography>
        <Link
          to="/changes"
          LinkProps={{ onClick: handleMenuItemPressed }}
        >{`Version ${config.APP_VERSION}`}</Link>
      </Stack>
    </Stack>
  );
}

/** Determine if a menu item is selected based on URL */
const isMenuItemOpen = (currentPath: string, path: string): boolean => {
  let isOpen = false;
  // Handle home case
  if (path === "/") {
    if (currentPath === "/") {
      isOpen = true;
    }
  } else if (currentPath.startsWith("/bookings")) {
    if (path === "/bookings/new") {
      isOpen = currentPath === path;
    } else {
      isOpen = currentPath.startsWith(path) && currentPath !== "/bookings/new";
    }
  } else {
    isOpen = currentPath.startsWith(path);
  }
  return isOpen;
};

export default LeftMenu;
