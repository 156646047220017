import { ScopeType } from "@dgi/utils";
import { Home as HomeIcon } from "@mui/icons-material";
import { Button, ButtonProps, Tooltip } from "@mui/material";
import { memo } from "react";

interface HomeButtonProps extends ButtonProps {
  tooltip?: string;
  permissions?: ScopeType | ScopeType[];
}

function HomeButton(props: HomeButtonProps) {
  const {
    tooltip = "",
    variant = "outlined",
    color = "primary",
    size = "small",
    ...otherButtonProps
  } = props;

  const tooltipText = tooltip || "";

  return (
    <Tooltip title={tooltipText}>
      <span>
        <Button
          {...otherButtonProps}
          size={size}
          variant={variant}
          color={color}
          startIcon={<HomeIcon />}
        >
          {props.children ?? "Home"}
        </Button>
      </span>
    </Tooltip>
  );
}

export default memo(HomeButton);
