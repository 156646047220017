import { config } from "config";
import { ExpressStatusResponse } from "types/interfaces";

export async function backendHealthCheck() {
  try {
    const response = await fetch(`${config.API_URL}/status`);
    const result: ExpressStatusResponse = await response.json();
    return result;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
