import { Breadcrumbs as MuIBreadcrumbs, Typography } from "@mui/material";
import { styled } from "@mui/system";
import { Link } from "react-router-dom";
import { Breadcrumb } from "types/types";

/* Example of props.bcState
[ 
    {text: "Home", link: "/"},
    {text: "Accounts", link: "/accounts"},
    {text: "DGI-Perth", link: "/accounts/10177"}
]
*/

const StyledBreadcrumb = styled(Link)(({ theme }) => ({
  color: theme.palette.text.primary,
  textDecoration: "none",
  "&:hover": {
    color: theme.palette.secondary.main,
  },
  fontSize: "1.2em",
}));

interface BreadcrumbsProps {
  state: Breadcrumb[];
}

function Breadcrumbs(props: BreadcrumbsProps) {
  const { state = [] } = props;

  return (
    <MuIBreadcrumbs
      sx={{
        "@media print": {
          display: "none",
        },
      }}
    >
      {state.map((bcItem, idx) => {
        return idx + 1 !== state.length ? (
          <StyledBreadcrumb key={idx} to={bcItem.link ?? ""}>
            {bcItem.text}
          </StyledBreadcrumb>
        ) : (
          <Typography key={idx} fontSize="1.2em" sx={{ fontWeight: "bold" }}>
            {bcItem.text}
          </Typography>
        );
      })}
    </MuIBreadcrumbs>
  );
}

export default Breadcrumbs;
