import {
  Link as MuiLink,
  LinkProps as MuiLinkProps,
  TypographyProps,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

// Used for internal links around the app, not external

interface LinkProps {
  to: string;
  children: React.ReactNode;
  variant?: TypographyProps["variant"];
  LinkProps?: MuiLinkProps;
}

function Link(props: LinkProps) {
  const { to, children, LinkProps, variant = "body2" } = props;
  return (
    <MuiLink
      {...LinkProps}
      component={RouterLink}
      variant={variant}
      to={to}
      color="inherit"
      sx={{
        "&:hover": {
          color: "secondary.main",
        },
      }}
    >
      {children}
    </MuiLink>
  );
}

export default Link;
