import * as Sentry from "@sentry/react";
import { config } from "config";
import { env } from "config/env";

// Initialze Sentry
Sentry.init({
  enabled: window.location.origin === "https://periculum.dgiglobal.com",
  dsn: config.SERVICES.SENTRY.DSN,
  maxBreadcrumbs: 50,
  environment: "production",
  release: `periculum@${config.APP_VERSION}`,

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: env.isProd ? 1.0 : 1.0,
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,

  integrations: [
    new Sentry.Replay({
      maskAllText: false,
      maskAllInputs: false,
    }),
  ],
});
