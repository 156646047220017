import { useApolloClient } from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";
import {
  Brightness4 as DarkModeIcon,
  Brightness7 as LightModeIcon,
  Mail as MailIcon,
  Sync as ClearCacheIcon,
} from "@mui/icons-material";
import {
  Badge,
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
} from "@mui/material";
import { useNetworkIcon } from "hooks";
import { useState } from "react";
import toast from "react-hot-toast";
import { useIDBStore } from "services/state/IDBStore";
import useStore from "services/state/StoreProvider";
import idb from "utils/indexedDB";

interface NavIconPaneProps {
  emailCount: number;
}

function NavIconPane(props: NavIconPaneProps) {
  const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null);
  const { emailCount } = props;

  const theme = useStore((state) => state.theme);
  const toggleTheme = useStore((state) => state.toggleTheme);
  const resetPersistedStore = useStore((store) => store.reset);

  const { logout } = useAuth0();
  const apolloClient = useApolloClient();
  const resetIDBStore = useIDBStore((store) => store.reset);

  async function handleLogoutPressed(globalLogout: boolean) {
    await handleClearCache();
    logout({
      logoutParams: {
        returnTo: window.location.origin,
        federated: globalLogout,
      },
    });
  }

  async function handleClearCache() {
    toast.promise(
      new Promise((resolve) => {
        resolve(
          Promise.all([
            apolloClient.clearStore(),
            window.caches.delete("network"),
            window.localStorage.removeItem("REACT_QUERY_OFFLINE_CACHE"),
            resetIDBStore(),
            resetPersistedStore(),
            idb.clearAllData(),
          ])
        );
      }),
      {
        loading: "Clearing out cached data...",
        error: (error) => error?.message ?? "Error clearing out cached data",
        success: "Cache cleared",
      }
    );
  }

  const { icon, tooltip } = useNetworkIcon();

  return (
    <Toolbar disableGutters>
      <Tooltip title="Clear cache">
        <IconButton onClick={handleClearCache} size="large">
          <ClearCacheIcon
            sx={{
              color: "primary.contrastText",
              "&:hover": {
                color: (theme) => theme.palette.grey[300],
              },
            }}
          />
        </IconButton>
      </Tooltip>
      <IconButton onClick={toggleTheme} size="large">
        <Tooltip title={`Toggle ${theme === "light" ? "dark" : "light"} mode`}>
          {theme === "light" ? (
            <LightModeIcon
              sx={{
                color: "primary.contrastText",
                "&:hover": {
                  color: (theme) => theme.palette.grey[300],
                },
              }}
            />
          ) : (
            <DarkModeIcon
              sx={{
                color: "primary.contrastText",
                "&:hover": {
                  color: (theme) => theme.palette.grey[300],
                },
              }}
            />
          )}
        </Tooltip>
      </IconButton>
      <IconButton
        onClick={() => {
          window.open("//gmail.com", "_blank");
        }}
        edge="end"
        color="inherit"
        size="large"
      >
        <Tooltip title="Open Employee Email">
          <Badge badgeContent={emailCount}>
            <MailIcon
              sx={{
                color: "primary.contrastText",
                "&:hover": {
                  color: (theme) => theme.palette.grey[300],
                },
              }}
            />
          </Badge>
        </Tooltip>
      </IconButton>
      <Box ml={6} display="flex" alignItems="center">
        <Tooltip title={tooltip}>{icon}</Tooltip>
      </Box>

      <Button
        // onClick={handleLogout}
        onClick={(event) => setMenuAnchor(event.currentTarget)}
        sx={{
          color: "primary.contrastText",
          border: "1px solid white",
          marginLeft: 8,
        }}
        size="small"
        variant="outlined"
      >
        Logout
      </Button>
      <Menu anchorEl={menuAnchor} open={!!menuAnchor} onClick={() => setMenuAnchor(null)}>
        <MenuItem id="logout-periculum" onClick={() => handleLogoutPressed(false)}>
          Periculum
        </MenuItem>
        <MenuItem id="logout-google" onClick={() => handleLogoutPressed(true)}>
          Google
        </MenuItem>
      </Menu>
    </Toolbar>
  );
}

export default NavIconPane;
