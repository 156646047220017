import { ArrowBack } from "@mui/icons-material";
import { Button, ButtonProps, Tooltip } from "@mui/material";
import { memo } from "react";
import { useHistory } from "react-router-dom";

interface BackButtonProps extends ButtonProps {
  tooltip?: string;
}

function BackButton(props: BackButtonProps) {
  const {
    tooltip = "",
    variant = "outlined",
    color = "primary",
    size = "small",
    onClick,
    ...otherButtonProps
  } = props;

  const history = useHistory();
  const tooltipText = tooltip || "";

  const defaultOnClick = () => history.goBack();

  return (
    <Tooltip title={tooltipText}>
      <span>
        <Button
          {...otherButtonProps}
          size={size}
          variant={variant}
          color={color}
          startIcon={<ArrowBack />}
          onClick={onClick ?? defaultOnClick}
        >
          {props.children}
        </Button>
      </span>
    </Tooltip>
  );
}

export default memo(BackButton);
