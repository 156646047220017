import { DBSchema, IDBPDatabase, openDB } from "idb";
import { log } from "./utils";
import * as Sentry from "@sentry/react";
import { GlobalAccountFieldsFragment } from "services/graphql/generated";

interface PericulumSchema {
  accounts: {
    value: GlobalAccountFieldsFragment;
    key: number;
    indexes: { client: string };
  };
}

type DatabaseSchema = DBSchema & PericulumSchema;

type StoreKey = keyof PericulumSchema;

/** Keys that are required to be able to merge data */
interface RequiredMergeKeys {
  id: number;
  dateDeleted?: string | null | undefined;
}

// Current version of the database schema is taken from the package.json file
// e.g. version 1.0.1 => 101
// const currentDBVersion = +packageJson.version.split(".").join("");

/** Bump this when zustand store schema changes and implement a
 *  migration strategy for the new version
 */
const IDB_STORE_SCHEMA_VERSION = 1;

class IndexedDB {
  private client: Promise<IDBPDatabase<DatabaseSchema>>;

  constructor() {
    // Open a connection to the database
    this.client = openDB<DatabaseSchema>("periculum-idb", IDB_STORE_SCHEMA_VERSION + 1, {
      // If the database is not yet created, or if the version number is different than the one in
      // the code, this upgrade function will be called
      upgrade(database, oldVersion, newVersion, transaction) {
        try {
          log("IndexedDB", `Upgrading from version ${oldVersion} to ${newVersion}`);

          // If store exists, clear it and recreate it in case schema has changed
          if (database.objectStoreNames.contains("accounts")) {
            database.deleteObjectStore("accounts");
          }
          // Create the store and specify the key path
          transaction.db.createObjectStore("accounts", { keyPath: "id" });
          log("IndexedDB", "Upgrade complete and database ready for use.");
          return database;
        } catch (error) {
          console.error(`[upgrade] Error upgrading database`);
          console.error(error);
        }
      },
      // If the database is open in another browser window, and a new version of the database is
      // available, the database will be blocked from upgrading until the other window is closed.
      blocked: () => {
        log("IndexedDB", "Database is blocked from upgrading while in use");
      },
      // If the database is open in another browser window, and a new version of the database is
      // available, the database will block any other attempts to open the database until the
      // upgrade is complete.
      blocking: () => {
        log("IndexedDB", "Database is blocking upgrading while in use");
      },
      // If the database is unexpectedly terminated, the terminated callback will be called
      terminated: () => {
        Sentry.captureMessage("IndexedDB Database is unexpected terminated");
        log("IndexedDB", "Database is unexpected terminated");
      },
    });
  }

  /**
   * Count the number of records in the store. Faster than loading all records and checking length.
   * @param store The name of the store
   * @returns Number of records in the store
   */
  async countData(store: StoreKey): Promise<number> {
    try {
      const db = await this.client;
      const t0 = window.performance.now();
      const count = await db.count(store);
      const t1 = window.performance.now();
      log(
        "IndexedDB",
        `Call to count all ${store} records took ${(t1 - t0).toFixed(2)} milliseconds.`
      );
      return count;
    } catch (error) {
      console.error(`[countData] Error counting data in [${store}]`);
      console.error(error);
      Sentry.captureException(error);
      return -1;
    }
  }

  /**
   * Get all data from a specific store.
   *
   * @param store The store key to use.
   * @returns A promise that resolves to the data.
   */
  async getAll<TStore extends StoreKey>(
    store: TStore
  ): Promise<DatabaseSchema[TStore]["value"][]> {
    try {
      const t0 = window.performance.now();

      // Get the database.
      const db = await this.client;

      // Get all the data from the store.
      const data = await db.getAll(store);

      const t1 = window.performance.now();

      log(
        "IndexedDB",
        `Call to get all ${store} found ${data.length} records and took ${(
          t1 - t0
        ).toFixed(2)} milliseconds.`
      );

      return data;
    } catch (error) {
      console.error(`[getAll] Error getting all data from [${store}]`);
      console.error(error);
      Sentry.captureException(error);
      return [];
    }
  }

  /**
   * Get a value from the IDB database.
   * @param store The store to query.
   * @param key The key to query.
   */
  async get<TStore extends StoreKey>(
    store: TStore,
    key: DatabaseSchema[TStore]["key"]
  ): Promise<DatabaseSchema[TStore]["value"] | undefined> {
    try {
      // Open the database.
      const db = await this.client;

      // Start timing the request.
      const t0 = window.performance.now();

      // Get the value.
      const data = await db.get(store, key);

      // Stop timing the request.
      const t1 = window.performance.now();

      // Log the timing.
      log(
        "IndexedDB",
        `Call to get ${key} from ${store} took ${(t1 - t0).toFixed(2)} milliseconds.`
      );

      // Return the value.
      return data;
    } catch (error) {
      console.error(`Error loading data from [${store}] with key [${key}]`);
      console.error(error);
      Sentry.captureException(error);
      return undefined;
    }
  }

  /**
   * Sets the value for a given store. Optionally clears the store first.
   * @param storeName The name of the store to set data for.
   * @param value The value to set.
   * @param clear Whether to clear the existing data first.
   */
  async setData<TStore extends StoreKey>(
    storeName: TStore,
    value: DatabaseSchema[TStore]["value"][],
    clear = true
  ): Promise<void> {
    try {
      // Start a timer for the operation
      const t0 = window.performance.now();

      // Get a reference to the database
      const db = await this.client;

      // Open a transaction to the specified store
      const tx = db.transaction(storeName, "readwrite");
      const store = tx.objectStore(storeName);

      // Clear the store if the clear parameter is true
      clear && store.clear();

      // Add each item in the value array to the store
      value.forEach((item) => {
        store.put(item);
      });

      // Wait for the transaction to complete
      await tx.done;

      const t1 = window.performance.now();
      log(
        "IndexedDB",
        `Call to setData on ${store.name} with ${value.length} records took ${(
          t1 - t0
        ).toFixed(2)} milliseconds.`
      );
    } catch (error) {
      console.error(`[setData] Error setting data in [${storeName}]`);
      console.error(error);
      Sentry.captureException(error);
    }
  }

  /**
   * Clears the data in the given store.
   * @param storeName The name of the store to clear.
   */
  async clearData(storeName: StoreKey): Promise<void> {
    try {
      // Start a timer for the operation
      const t0 = window.performance.now();

      // Open a connection to the database
      const db = await this.client;

      // Clear the data from the store
      await db.clear(storeName);

      // End the timer and log the result
      const t1 = window.performance.now();
      log(
        "IndexedDB",
        `Call to clear ${storeName} took ${(t1 - t0).toFixed(2)} milliseconds.`
      );
    } catch (error) {
      console.error(`[clearData] Error clearing data in [${storeName}]`);
      console.error(error);
      Sentry.captureException(error);
    }
  }

  /**
   * Clear all data in all object stores in the database.
   */
  async clearAllData(): Promise<void> {
    try {
      // Start the timer.
      const t0 = window.performance.now();

      // Get a database connection.
      const db = await this.client;

      // Get the names of all object stores in the database.
      const stores = db.objectStoreNames;

      // Clear each object store in the database.
      await Promise.all([...stores].map((store) => db.clear(store)));

      // Stop the timer.
      const t1 = window.performance.now();
      log(
        "IndexedDB",
        `Call to clear all stores took ${(t1 - t0).toFixed(2)} milliseconds.`
      );
    } catch (error) {
      console.error(`[clearAllData] Error clearing all data`);
      console.error(error);
      Sentry.captureException(error);
    }
  }

  /**
   * Merges data from an array of updates into the data in the given store.
   * - If an item is in the original data but not in the updates, it will be left in the store.
   * - If an item is in the updates but not in the original data, it will be added to the store.
   * - If an item is in both the updates and the original data, it will be updated in the store.
   * - If an item is in the updates but has a dateDeleted field, it will be removed from the store.
   * - If an item is in the original data but has a dateDeleted field, it will be removed from the store.
   * @param storeName name of the store
   * @param updates new data to merge into the store
   */
  async mergeData<
    TStore extends StoreKey,
    TStoreValue extends DatabaseSchema[TStore]["value"]
  >(storeName: TStore, updates: (TStoreValue & RequiredMergeKeys)[]): Promise<void> {
    try {
      // Get the current data in the store
      const originalRecords = await this.getAll(storeName);
      // Create a map of the new data
      const updateMap = Object.fromEntries(updates.map((update) => [update.id, update]));

      // Merge the original data with the new data
      const mergedData = originalRecords
        .map((item) => {
          if (updateMap[item.id]) {
            return updateMap[item.id];
          } else {
            return item;
          }
        })
        .filter((item) => "dateDeleted" in item && item.dateDeleted === null);

      // Add any new data that wasn't in the original store
      updates.forEach((item) => {
        if (!item.dateDeleted && !mergedData.find((i) => i.id === item.id)) {
          mergedData.push(item);
        }
      });

      // Store the merged data
      await this.setData(storeName, mergedData);
    } catch (error) {
      console.error(`[mergeData] Error merging store data`);
      console.error(error);
      Sentry.captureException(error);
    }
  }
}

export default new IndexedDB();
