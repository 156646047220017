import { useMountEffect } from "@react-hookz/web";
import { differenceInSeconds } from "date-fns";
import { useCallback } from "react";
import { useIDBStore } from "services/state/IDBStore";
import indexedDB from "utils/indexedDB";

// Store accounts with in memory zustand store after first load from IDB
export let lastIDBAccountsRefresh: Date | null = null;

export function useAccounts() {
  const cachedAccounts = useIDBStore((state) => state.accounts);
  const setCachedAccounts = useIDBStore((state) => state.setAccounts);

  // Refresh accounts from IDB on mount
  useMountEffect(() => {
    loadAccounts();
  });

  /** Load accounts from IDB into in memory store. Max once every 30 seconds to prevent
   *  multiple components on a single screen all triggering a refresh at the same time
   */
  const loadAccounts = useCallback(async () => {
    if (
      !lastIDBAccountsRefresh ||
      differenceInSeconds(new Date(), lastIDBAccountsRefresh) >= 30
    ) {
      const accounts = await indexedDB.getAll("accounts");
      setCachedAccounts(accounts);
      lastIDBAccountsRefresh = new Date();
    }
  }, [setCachedAccounts]);

  /** Force a refetch of accounts from IDB manually */
  const forceFullRefresh = useCallback(() => {
    lastIDBAccountsRefresh = null;
    loadAccounts();
  }, [loadAccounts]);

  return { accounts: cachedAccounts, loadAccounts, forceFullRefresh };
}

/** Ensure next time useAccounts is called, it loads fresh data from IDB */
export const clearLastRefreshTimer = () => (lastIDBAccountsRefresh = null);
