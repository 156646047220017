import { ThemeOptions } from "@mui/material";

// Colour scheme generated at coolors.co/ca302c-000000-191716-440d0f-603a40
// const persian_red = "#CA302C"; // or dgi-red dgired
// const registration_black = "#000000";
// const eerie_black = "#191716";
// const bulgarian_rose = "#440D0F";
// const dark_puce = "#603A40";

export const dgiBlack = "#191716"; // rgb(25,23,22)
export const dgiRed = "#CA302C";

// const commonThemeConfig: CommonThemeConfig = {
const componentsConfig: ThemeOptions["components"] = {
  MuiRadio: {
    defaultProps: {
      disableRipple: true,
    },
  },
  MuiStepButton: {
    defaultProps: {
      disableRipple: true,
    },
    styleOverrides: {
      root: {
        "&.Mui-active": {
          color: "#ff0000",
        },
        active: {
          color: "#ff0000",
        },
      },
    },
  },
  MuiStepper: {
    styleOverrides: {
      root: {
        "&.Mui-active": {
          color: "#ff0000",
        },
      }
    },
  },
  MuiCard: {
    defaultProps: {
      variant: "outlined",
    },
  },
  MuiTable: {
    defaultProps: {
      size: "small",
    },
  },
  MuiCardActions: {
    styleOverrides: {
      root: {
        justifyContent: "flex-end",
      },
    },
  },
  MuiTextField: {
    defaultProps: {
      color: "secondary",
      margin: "dense",
      size: "small",
      fullWidth: true,
    },
  },
  MuiTypography: {
    defaultProps: {
      variant: "body2",
    },
  },
  MuiButton: {
    defaultProps: {
      variant: "contained",
      color: "secondary",
      size: "small",
    },
  },
  MuiBadge: {
    defaultProps: {
      color: "secondary",
    },
  },
  MuiChip: {
    defaultProps: {
      size: "small",
    },
  },
  MuiCircularProgress: {
    defaultProps: {
      color: "secondary",
    },
  },
  MuiTab: {
    defaultProps: {
      disableRipple: true,
    },
  },
};

export const lightTheme: ThemeOptions = {
  spacing: 4,
  palette: {
    mode: "light",
    primary: {
      main: dgiBlack,
    },
    secondary: {
      main: dgiRed,
    },
  },
  components: {
    ...componentsConfig,
    MuiCard: {
      ...componentsConfig.MuiCard,
      styleOverrides: {
        root: {
          width: "100%",
        },
      },
    },
    MuiRadio: {
      ...componentsConfig.MuiRadio,
    },
    MuiTextField: {
      ...componentsConfig.MuiTextField,
    },
    MuiButton: {
      ...componentsConfig.MuiButton,
    },
    MuiBadge: {
      ...componentsConfig.MuiBadge,
    },
    MuiCircularProgress: {
      ...componentsConfig.MuiCircularProgress,
    },
  },
};

export const darkTheme: ThemeOptions = {
  spacing: 4,
  palette: {
    mode: "dark",
    primary: {
      main: dgiBlack,
    },
    secondary: {
      main: dgiRed,
    },
  },
  components: {
    ...componentsConfig,
    MuiRadio: {
      ...componentsConfig.MuiRadio,
    },
    MuiTextField: {
      ...componentsConfig.MuiTextField,
    },
    MuiButton: {
      ...componentsConfig.MuiButton,
    },
    MuiBadge: {
      ...componentsConfig.MuiBadge,
    },
    MuiCircularProgress: {
      ...componentsConfig.MuiCircularProgress,
    },
  },
};
