import { getScopeLabel, ScopeType } from "@dgi/utils";
import {
  Add as AddIcon,
  ArrowBack,
  Cancel,
  Delete as DeleteIcon,
  Edit as EditIcon,
} from "@mui/icons-material";
import { Button, ButtonProps, Tooltip } from "@mui/material";
import { useHasPermission } from "hooks";
import { memo } from "react";

type ButtonTypeOption = "add" | "back" | "edit" | "delete" | "cancel" | "other";

interface DGIButtonProps extends ButtonProps {
  buttonType: ButtonTypeOption;
  permissions?: ScopeType | ScopeType[];
  tooltip?: string;
}

const iconMap: Record<ButtonTypeOption, JSX.Element | null> = {
  add: <AddIcon />,
  back: <ArrowBack />,
  edit: <EditIcon />,
  delete: <DeleteIcon />,
  cancel: <Cancel />,
  other: null,
};

const defaultColorMap: Record<ButtonTypeOption, "primary" | "secondary"> = {
  add: "secondary",
  back: "primary",
  edit: "secondary",
  delete: "primary",
  cancel: "primary",
  other: "secondary",
};

function DGIButton(props: DGIButtonProps) {
  const {
    buttonType,
    tooltip = "",
    variant = "outlined",
    color,
    size = "small",
    permissions = [],
    ...otherButtonProps
  } = props;

  const [hasAccess, missingPermissions] = useHasPermission({
    matchAll: typeof permissions === "string" ? [permissions] : permissions,
  });

  const buttonColor = defaultColorMap[buttonType];

  // Create a tooltip letting the user know they're missing a permissions to perform
  // this action
  let noPermissionTooltip = "";
  if (!hasAccess) {
    if (typeof permissions === "string") {
      noPermissionTooltip = `You do not have permission to ${getScopeLabel(
        permissions
      ).toLowerCase()}`;
    } else {
      noPermissionTooltip = `You do not have permission to ${missingPermissions
        .map((scope) => getScopeLabel(scope).toLowerCase())
        .join(" or ")}`;
    }
  }

  const tooltipText = tooltip || noPermissionTooltip || "";

  return (
    <Tooltip title={tooltipText}>
      <span>
        <Button
          {...otherButtonProps}
          size={size}
          variant={variant}
          color={color ?? buttonColor}
          startIcon={iconMap[buttonType] ?? otherButtonProps.startIcon}
          disabled={!hasAccess || otherButtonProps.disabled}
        >
          {props.children}
        </Button>
      </span>
    </Tooltip>
  );
}

export default memo(DGIButton);
