import { getScopes, ScopeType } from "@dgi/utils";
import useStore from "services/state/StoreProvider";

/**
 * Check if a user has all of the 'matchAll' permissions and
 * at least 1 of the 'matchAny' permissions.
 */
export function useHasPermission({
  matchAll,
  matchAny = [],
}: {
  matchAll: ScopeType[];
  matchAny?: ScopeType[];
}): [boolean, ScopeType[]] {
  const profile = useStore((state) => state.profile);
  const employeePermissions = getScopes(profile.permissions.map((p) => p.name));

  const hasAllRequired = matchAll.every((permission) =>
    employeePermissions.includes(permission)
  );
  const hasAtleastOne =
    matchAny.length === 0 ||
    matchAny.some((permission) => employeePermissions.includes(permission));

  // Find all permissions within matchAll that employee is missing

  const matchAllMissingPermissions = matchAll.filter(
    (permission) => !employeePermissions.includes(permission)
  );

  return [hasAllRequired && hasAtleastOne, matchAllMissingPermissions];
}
