import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  InMemoryCache,
  split,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { getMainDefinition } from "@apollo/client/utilities";
import { useAuth0 } from "@auth0/auth0-react";
import * as Sentry from "@sentry/react";
import { ErrorPage } from "components";
import { config } from "config";
import { createClient } from "graphql-ws";
import React from "react";
import packageInfo from "../../package.json";
import { typePolicies } from "./ApolloTypePolicy";

interface AuthorizedApolloProviderProps {
  children: React.ReactNode;
}

const httpLink = createHttpLink({
  uri: `${config.API_URL}/graphql`,
});

const errorLink = onError(({ networkError, graphQLErrors, operation }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach((error) => {
      const { message, path } = error;
      console.error(`[GraphQL error]: ${message} at ${path?.join(", ")}`);
      Sentry.setContext("Graphql", {
        type: "graphql",
        client: "apollo-client",
        path: path?.join(", "),
        operation: operation.operationName,
        variables: JSON.stringify(operation.variables),
      });
      Sentry.captureException(new Error(message));
    });
  }
  if (networkError) {
    console.error(`[Network error]: ${networkError}`);
    Sentry.captureException(networkError);
  }
});

function AuthorizedApolloProvider(props: AuthorizedApolloProviderProps) {
  const { getAccessTokenSilently, loginWithRedirect, isLoading, error } = useAuth0();

  const wsLink = new GraphQLWsLink(
    createClient({
      url: `${config.SUBSCRIPTION_URL}`,
      connectionParams: async () => {
        try {
          const token = await getAccessTokenSilently();
          return { authorization: token };
        } catch (error) {
          console.warn("Unable to silently get access token for ws connection");
          console.error(error instanceof Error ? error.message : "Auth error");
        }
      },
    })
  );

  const authLink = setContext(async (_, { headers }) => {
    try {
      const token = await getAccessTokenSilently();
      return {
        headers: {
          ...headers,
          authorization: token ? `Bearer ${token}` : "",
        },
      };
    } catch (error) {
      console.warn("Unable to silently get access token, redirecting to login");
      console.error(error instanceof Error ? error.message : "Auth error");
      loginWithRedirect();
    }
  });

  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === "OperationDefinition" &&
        definition.operation === "subscription"
      );
    },
    wsLink,
    httpLink
  );

  const apolloClient = new ApolloClient({
    name: packageInfo.name,
    version: packageInfo.version,
    connectToDevTools: true,
    uri: `${config.API_URL}/graphql`,
    cache: new InMemoryCache({ typePolicies }),
    link: authLink.concat(errorLink).concat(splitLink),
  });

  // Render nothing to avoid flicker
  if (isLoading) {
    return null;
  }

  if (error) {
    return <ErrorPage error={error} />;
  }

  return <ApolloProvider client={apolloClient}>{props.children}</ApolloProvider>;
}

export default AuthorizedApolloProvider;
