import SplashScreen from "components/SplashScreen";
import { lazy, Suspense } from "react";
import { Route, Switch } from "react-router-dom";

const routes = [
  {
    path: "/",
    exact: true,
    element: lazy(() => import("screens/Home/HomePage")),
  },
  {
    path: "/changes",
    exact: true,
    element: lazy(() => import("screens/Home/ChangelogPage")),
  },
  {
    path: "/login",
    exact: false,
    element: lazy(() => import("screens/Login/Login")),
  },
  {
    path: "/forms",
    exact: false,
    element: lazy(() => import("screens/Forms/FormsContainer")),
  },
  {
    path: "/bookings",
    exact: false,
    element: lazy(() => import("screens/Booking/BookingContainer")),
  },
  {
    path: "/quotes",
    exact: false,
    element: lazy(() => import("screens/Quote/QuoteContainer")),
  },
  {
    path: "/reporting",
    exact: false,
    element: lazy(() => import("screens/Reporting/ReportingContainer")),
  },
  {
    path: "/profile",
    exact: false,
    element: lazy(() => import("screens/Profile/ProfileContainer")),
  },
  {
    path: "/contacts",
    exact: false,
    element: lazy(() => import("screens/Contact/ContactContainer")),
  },
  {
    path: "/settings",
    exact: false,
    element: lazy(() => import("screens/Settings/SettingsContainer")),
  },
  {
    path: "/accounts",
    exact: false,
    element: lazy(() => import("screens/Account/AccountContainer")),
  },
  {
    path: "/admin",
    exact: false,
    element: lazy(() => import("screens/Admin/AdminContainer")),
  },
  {
    path: "/charges",
    exact: false,
    element: lazy(() => import("screens/Charges/ChargesContainer")),
  },
  {
    path: "/search",
    exact: true,
    element: lazy(() => import("screens/Search/SearchPage")),
  },
  // ******************************************************
  // *************** 404 route must be last ***************
  // ******************************************************
  // todo: redirect home?
  {
    path: "*",
    exact: false,
    element: lazy(() => import("components/PageNotFound")),
    permission: undefined,
  },
];

function PrivateRoutes() {
  return (
    <>
      <Suspense
        fallback={<SplashScreen loadingText="Starting Periculum..." position="center" />}
      >
        <Switch>
          {routes.map((route) => (
            <Route key={route.path} path={route.path} exact={route.exact}>
              {<route.element />}
            </Route>
          ))}
        </Switch>
      </Suspense>
    </>
  );
}

export default PrivateRoutes;
