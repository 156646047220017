import { GlobalAccountFieldsFragment } from "services/graphql/generated";
import { create } from "zustand";

/**
 * Used for loading data from IDB that we dont want to persist to local storage.
 * Store prevents useAccounts from having to async fetch from IDB
 * on every mount.
 *  */

// todo: refactor this to 'in memory store' and use it for things that should not persist to local storage

type stateStatus = "not loaded" | "fetching" | "loaded";

type State = {
  accounts: GlobalAccountFieldsFragment[];
  accountsStatus: stateStatus;
};
type Actions = {
  setAccounts: (accounts: GlobalAccountFieldsFragment[]) => void;
  /** Has the entity been loaded from IDB into memory */
  setStatus: (entity: "accounts", newStatus: stateStatus) => void;
  reset: () => void;
};

type IDBStore = State & Actions;

const initialIDBStore: State = {
  accounts: [],
  accountsStatus: "not loaded",
};

export const useIDBStore = create<IDBStore>()((set) => ({
  ...initialIDBStore,
  setAccounts: (accounts) => set({ accounts }),
  setStatus: (entity, newStatus) => set({ [`${entity}Status`]: newStatus }),
  reset: () => set(initialIDBStore),
}));
