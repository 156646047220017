import * as Sentry from "@sentry/react";
import { differenceInMinutes } from "date-fns";
import { useAccounts } from "hooks";
import ms from "ms";
import { memo, useEffect } from "react";
import isEqual from "react-fast-compare";
import {
  useProfileQuery,
  useRecentlyUpdatedAccountsQuery,
} from "services/graphql/generated";
import indexedDB from "utils/indexedDB";
import { profileSelector } from "./selectors";
import useStore from "./StoreProvider";

interface ProfileProviderProps {
  children: React.ReactNode;
}

/** Subscribe to the remote profile + other data */
export function ProfileProvider(props: ProfileProviderProps) {
  const profile = useStore(profileSelector);

  const updateProfile = useStore((state) => state.updateProfile);
  const lastAccountsRefresh = useStore((store) => store.lastAccountsRefresh);
  const setLastAccountsRefresh = useStore((store) => store.setLastAccountsRefresh);
  const isHydrated = useStore((store) => store._hasHydrated);

  const { forceFullRefresh } = useAccounts();

  // Add the user context to Sentry
  useEffect(() => {
    Sentry.setContext("employee", {
      id: profile.employee.id,
      name: profile.employee.fullname,
      officeID: profile.periculumOffice?.office?.id,
      officeName: profile.periculumOffice?.office?.client,
    });
  }, [
    profile.employee.fullname,
    profile.employee.id,
    profile.periculumOffice?.office?.client,
    profile.periculumOffice?.office?.id,
  ]);

  // Poll for profile changes to prevent cached profile becoming stale
  useProfileQuery({
    onCompleted: (data) => {
      const hasProfileChange = !isEqual(data.profile, profile);
      hasProfileChange && updateProfile(data.profile);
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    pollInterval: ms("10m"),
  });

  const lastAccountsCheck = differenceInMinutes(
    new Date(),
    new Date(lastAccountsRefresh)
  );

  // Get accounts updated in the last x minutes to avoid a massive query
  useRecentlyUpdatedAccountsQuery({
    variables: {
      since: lastAccountsRefresh,
    },
    // Wait between 10 and 12 minutes before checking again
    skip: !isHydrated || (isHydrated && lastAccountsCheck <= 10),
    onCompleted: async ({ recentlyUpdatedAccounts }) => {
      await indexedDB.mergeData("accounts", recentlyUpdatedAccounts);
      setLastAccountsRefresh(new Date());
      // Clear in memory accounts cache to force reload from IDB
      forceFullRefresh();
    },
    fetchPolicy: "no-cache",
    pollInterval: ms("2m"),
  });

  return <>{props.children}</>;
}

export default memo(ProfileProvider);
