import { Cancel } from "@mui/icons-material";
import { Button, ButtonProps } from "@mui/material";
import { memo } from "react";

interface BackButtonProps extends ButtonProps {
  tooltip?: string;
}

function CancelButton(props: BackButtonProps) {
  const {
    variant = "outlined",
    color = "primary",
    size = "small",
    onClick,
    ...otherButtonProps
  } = props;

  return (
    <Button
      {...otherButtonProps}
      size={size}
      variant={variant}
      color={color}
      startIcon={<Cancel />}
      onClick={onClick}
    >
      {props.children}
    </Button>
  );
}

export default memo(CancelButton);
