import { getScopeLabel, ScopeType } from "@dgi/utils";
import { Save as SaveIcon } from "@mui/icons-material";
import { IconButton, IconButtonProps, Tooltip } from "@mui/material";
import { useHasPermission } from "hooks";
import { memo } from "react";

interface SaveIconButtonProps extends IconButtonProps {
  tooltip?: string;
  permissions?: ScopeType | ScopeType[];
}

function SaveIconButton(props: SaveIconButtonProps) {
  const {
    size = "small",
    tooltip = "",
    color,
    permissions = [],
    ...otherButtonProps
  } = props;

  const [hasAccess, missingPermissions] = useHasPermission({
    matchAll: typeof permissions === "string" ? [permissions] : permissions,
  });

  // Create a tooltip letting the user know they're missing a permissions to perform
  // this action
  let noPermissionTooltip = "";
  if (!hasAccess) {
    if (typeof permissions === "string") {
      noPermissionTooltip = `You do not have permission to ${getScopeLabel(
        permissions
      ).toLowerCase()}`;
    } else {
      noPermissionTooltip = `You do not have permission to ${missingPermissions
        .map((scope) => getScopeLabel(scope).toLowerCase())
        .join(" or ")}`;
    }
  }

  const tooltipText = tooltip || noPermissionTooltip || "";

  return (
    <Tooltip title={tooltipText}>
      <span>
        <IconButton
          {...otherButtonProps}
          size={size}
          color={color}
          disabled={!hasAccess || otherButtonProps.disabled}
        >
          <SaveIcon />
        </IconButton>
      </span>
    </Tooltip>
  );
}

export default memo(SaveIconButton);
