import { styled } from "@mui/material";
import darkPatternBackground from "assets/images/dark_triangle_pattern_background.png";
import patternBackground from "assets/images/triangle_pattern_background.jpg";
import { memo } from "react";

const BackgroundContainer = styled("div")(({ theme }) => ({
  minHeight: "100vh",
  height: "100%",
  backgroundImage:
    theme.palette.mode === "light"
      ? `url(${patternBackground})`
      : `url(${darkPatternBackground})`,
}));

interface BackgroundProps {
  children: React.ReactNode | React.ReactNode[];
}

function Background(props: BackgroundProps) {
  return (
    <BackgroundContainer>
      <span>{props.children}</span>
    </BackgroundContainer>
  );
}

export default memo(Background);
