import { Backdrop, SpeedDial, SpeedDialAction, SpeedDialIcon } from "@mui/material";
import { memo, useState } from "react";
import { AppIcons } from "utils/constants";

const actions = [
  { icon: AppIcons.Bookings, name: "Create Booking", page: "/bookings/new" },
  { icon: AppIcons.Quotes, name: "Create Quote", page: "/quotes/new" },
  { icon: AppIcons.Accounts, name: "Create Account", page: "/accounts/new" },
  { icon: AppIcons.Contacts, name: "Create Contact", page: "/contacts/new" },
];

function SpeedDialer() {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <Backdrop open={open} sx={{ zIndex: 3 }} />
      <SpeedDial
        ariaLabel="SpeedDial"
        icon={<SpeedDialIcon />}
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
        direction="up"
        FabProps={{ color: "secondary", size: "small" }}
        sx={{
          position: "fixed",
          bottom: "0.5vw",
          right: "0",
          "@media print": {
            display: "none",
          },
        }}
      >
        {actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={<action.icon />}
            tooltipTitle={action.name}
            onClick={() => window.open(action.page, "_blank")}
          />
        ))}
      </SpeedDial>
    </>
  );
}

export default memo(SpeedDialer);
