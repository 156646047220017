import bytes from "bytes";

/** Convert currency to its local format */
export const formatCurrency = (amount: number, locale: string, currencyCode: string) => {
  if (isNaN(amount)) {
    return "0";
  }
  if (locale === "ar-BH") {
    return `BD ${amount}`;
  }
  return amount.toLocaleString(locale, {
    style: "currency",
    currency: currencyCode,
  });
};

/**
 * Check if string is a valid DGI HAWB
 * @param coyhawb hawb to check
 */
export function isJobNumber(coyhawb: string): boolean {
  const coyhawbRegex = RegExp("^([a-zA-Z]{3}[0-9]{5}$)");
  return coyhawbRegex.test(coyhawb);
}

export const getUserIDFromEmail = (email: string) => `google-apps|${email.toLowerCase()}`;

/** Remove the 'Z' off an ISO timezone string so it won't be changed when loading it into a JS Date */
export const removeTimezone = (isoDate: string) => {
  if (isoDate.slice(-1) === "Z") {
    return isoDate.slice(0, -1);
  }
  return isoDate;
};

/**
 * Get the account label/type based on the flags enabled
 * @param dgioffice Boolean representing if the account belongs to an office
 * @param isAgent Boolean representing if the account is an agent
 * @param isSupplier Boolean representing if the account is a supplier
 * @returns "DGI Office" | "Agent" | "Supplier" | "Other"
 */
export function getAccountType(
  dgioffice: boolean,
  isAgent: boolean,
  isSupplier: boolean
) {
  // The priority is dgioffice > agent = supplier > client
  if (dgioffice) {
    return "DGI Office";
  } else if (isAgent && isSupplier) {
    return "Agent and supplier";
  } else if (isAgent) {
    return "Agent";
  } else if (isSupplier) {
    return "Supplier";
  } else {
    return "Client";
  }
}

/**
 * Render bytes in a human readable format e.g, 1024 -> 1KB
 * @param bytesValue A number of bytes
 * @returns A string representing the number of bytes in a human readable format
 */
export const renderFilesize = (bytesValue: number): string => {
  if (isNaN(bytesValue)) {
    return "0";
  }

  return bytes(bytesValue, {
    unitSeparator: " ",
  });
};

/**
 * Check if a string is a valid email address
 * @param email Email address to test
 * @returns Boolean representing if the email is valid
 */
export const isValidEmail = (email: string): boolean => {
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email.trim().toLowerCase());
};

/**
 * Take an array of emails and return an array of unique and valid emails
 * @param emails Array of strings
 * @returns Array of email strings
 */
export const validateEmailAddresses = (emails: string[]): string[] => {
  const uniqueEmails = new Set<string>();
  emails
    .map((email) => email.trim().toLowerCase())
    .filter((email) => isValidEmail(email))
    .forEach((email) => uniqueEmails.add(email));
  return Array.from(uniqueEmails);
};

/**
 * Convert milliseconds to a human readable string with minutes and seconds
 * @param milliseconds Number of milliseconds
 * @returns String representing the time in minutes and seconds
 */
export const millisToMinutesAndSeconds = (milliseconds: number): string => {
  const minutes = Math.floor(milliseconds / 60000);
  const seconds = Number(((milliseconds % 60000) / 1000).toFixed(0));
  return minutes + ":" + (seconds < 10 ? "0" : "") + seconds;
};

/**
 * Get initials from a name
 * @example "Allan Bunker" returns "AB"
 * @param  {string} name    string representing employee name
 * @return {string}         Returns upper case initials
 */
export function getInitialsFromName(name: string): string {
  return (
    name
      ?.match(/(^\S\S?|\b\S)?/g)
      ?.join("")
      ?.match(/(^\S|\S$)?/g)
      ?.join("")
      ?.toUpperCase() ?? ""
  );
}

/**
 * Get the currency symbol. e.g. $, £, €
 * @param locale Currency locale e.g. en-GB
 * @param moneycode Currency code e.g. GBP
 * @returns Currency symbol
 */
export const getCurrencySymbol = (locale: string, moneycode: string): string => {
  // Don't display Arabic currency symbols
  if (locale === "ar-BH") {
    return "BD";
  } else {
    return (0)
      .toLocaleString(locale, {
        style: "currency",
        currency: moneycode,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })
      .replace(/\d/g, "")
      .trim();
  }
};

/**
 * Get a random number, optionally, between a min and max
 * @param min An include minimum number
 * @param max An exclusive maximum number
 * @returns A random number between min and max-1 (inclusive)
 */
export const getRandomInt = (min: number = 1, max: number = 10000): number =>
  Math.floor(Math.random() * (max - min) + min);

/** Get the users local timezone e.g, Australia/Perth */
export const getTimezone = () => Intl.DateTimeFormat().resolvedOptions().timeZone;

/** Get an error message from an error object */
export const getErrorMessage = (error: unknown) =>
  error instanceof Error ? error.message : "Unknown error occurred";
