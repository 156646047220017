import {
  SignalCellular0Bar,
  SignalCellular1Bar,
  SignalCellular3Bar,
  SignalCellular4Bar,
  SignalCellularConnectedNoInternet0Bar,
} from "@mui/icons-material";
import { SxProps } from "@mui/material";
import { useNetworkState } from "@react-hookz/web";

/** Check the browsers internet speed */
export function useNetworkIcon() {
  const { online = false, downlink = 0 } = useNetworkState();

  let iconStyle: SxProps = {
    ...(!online && { color: "error.main" }),
  };

  let icon = <SignalCellular4Bar sx={iconStyle} />;
  let tooltip = "Unknown network speed";

  if (!online) {
    icon = <SignalCellularConnectedNoInternet0Bar sx={iconStyle} />;
    tooltip = "No network connection";
  } else if (downlink <= 1) {
    iconStyle = { color: "error.main" };
    icon = <SignalCellular0Bar sx={iconStyle} />;
    tooltip = "Network is very slow";
  } else if (downlink > 1 && downlink <= 3) {
    icon = <SignalCellular1Bar sx={iconStyle} />;
    tooltip = "Network is slow";
  } else if (downlink > 3 && downlink <= 9) {
    icon = <SignalCellular3Bar sx={iconStyle} />;
    tooltip = "Network is good";
    // } else if (downlink > 6 && downlink <= 9) {
    //   icon = <SignalCellular3Bar sx={iconStyle} />;
    //   tooltip = "Network is good";
  } else if (downlink > 9) {
    icon = <SignalCellular4Bar sx={iconStyle} />;
    tooltip = "Network is very fast";
  }

  return { icon, tooltip, downlink: downlink ?? 0 };
}
