import { StrictTypedTypePolicies } from "services/graphql/generated/apollo-client-helpers";

export const typePolicies: StrictTypedTypePolicies = {
  // Required as permissions may come from role OR direct and ID will be the same
  Permission: {
    keyFields: ["name", "source"],
  },
  Booking: {
    keyFields: ["coyhawb"],
    fields: {
      bookingCharges: {
        // Always overwrite the existing array
        merge: false,
      },
    },
  },
};
